import { useNavigation } from "@react-navigation/native";
import {
  Box,
  Button,
  Center,
  Checkbox,
  FlatList,
  Flex,
  Heading,
  Text,
  View,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import SellConditionsModal from "../components/SellConditionsModal";
import { WITH_CONTENT } from "../constants/Layout";
import useErrorHandler from "../hooks/useErrorHandler";
import useTranslate from "../hooks/useTranslate";
import { LocalizationContext } from "../i18n/LocalizationProvider";
import {
  useAudioGuideQuery,
  useCreatePaymentIntentMutation,
} from "../types-graphql.d";

export default function OrderScreen({ route }: { route: any }) {
  const audioGuideId = route.params.guideId;
  const i18n = useContext<LocalizationContext>(LocalizationContext);
  const { data } = useAudioGuideQuery({
    variables: {
      id: audioGuideId,
      lang: i18n.getLocale(),
    },
  });
  const navigation = useNavigation();
  const [createPaymentIntentMutation] = useCreatePaymentIntentMutation();
  const [loading, setLoading] = useState(false);
  const { handle: handleError } = useErrorHandler();
  const [checked, setChecked] = useState(false);

  const conditionsModal = useRef(null);

  function openConditionsModal() {
    conditionsModal?.current();
  }

  async function goToOrder() {
    try {
      setLoading(true);
      //TODO: afegir el deviceId a la comanda
      const data = await createPaymentIntentMutation({
        variables: { orderInput: { audioGuideId: audioGuideId } },
      });
      setLoading(false);
      const clientSecret = data.data?.createPaymentIntent?.clientSecret;
      navigation.navigate("Stripe", {
        clientSecret: clientSecret,
        audioGuideId: audioGuideId,
      });
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <Center>
      <SellConditionsModal sellConditionsModal={conditionsModal} />
      <View maxWidth={"750px"} width={"100%"}>
        <Heading size="md" color="primary.500" px="6" py="2">
          {useTranslate("Resumen de la compra")}
        </Heading>
        <Box px="6">
          <Flex direction="row" style={{ justifyContent: "space-between" }}>
            <Text py="6" fontSize="md" fontWeight="bold" width="5/6">
              {data?.audioGuide?.title}
            </Text>
            <Text py="6" fontSize="md" fontWeight="bold">
              {data?.audioGuide?.price} €
            </Text>
          </Flex>
          <Center>
            <Box py="4">
              <Checkbox
                value="checked"
                onChange={() => {
                  setChecked(!checked);
                }}
                accessibilityLabel="Acepto las condiciones de venta"
                justifyContent="start"                
              >
                <Text>{useTranslate("Acepto las condiciones de venta")}.{" "}
                <Button p="0" onPress={openConditionsModal} variant="link">{useTranslate("Consultar condiciones de venta")}
                </Button></Text>
              </Checkbox>
            </Box>
            <Button
              w="sm"
              bg="primary.500"
              onPress={() => {
                goToOrder();
              }}
              isLoading={loading}
              disabled={!checked}
            >
              {useTranslate("Pagar")}
            </Button>
          </Center>
        </Box>
      </View>
    </Center>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

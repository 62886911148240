import { MaterialIcons } from "@expo/vector-icons";
import {
  Alert,
  Box,
  Button,
  Center,
  Heading,
  Icon,
  Image,
  Input,
  Text,
  View,
  VStack,
} from "native-base";
import React from "react";

import { RootStackScreenProps } from "@/types";
import { useForgotPasswordMutation } from "../../types-graphql.d";
import useErrorHandler from "../../hooks/useErrorHandler";
import useTranslate from "../../hooks/useTranslate";

export default function ResetPasswordScreen({
  navigation,
}: RootStackScreenProps<"ResetPassword">) {
  const [email, onChangeEmail] = React.useState("");
  const [isLoading, setisLoading] = React.useState(false);
  const [forgotPasswordMutation] = useForgotPasswordMutation();
  const { handle: handleError } = useErrorHandler();

  function goToCodePage() {
    navigation.navigate("NewPassword");
  }

  const onResetPassword = async () => {
    try {
      setisLoading(true);
      const data = await forgotPasswordMutation({
        variables: { input: { email } },
      });
      if (data?.data?.forgotPassword) {
        onChangeEmail("");
        navigation.navigate("NewPassword");
      }
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <View
      bgColor="white"
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View maxWidth={"md"} width={"100%"} flex="1" pt="16">
        <View style={{ alignItems: "center" }}>
          <Heading color={"primary.500"} mb="4">
            {useTranslate("Recuperar contraseña")}
          </Heading>
        </View>
        <View>
          <Input
            value={email}
            onChangeText={onChangeEmail}
            placeholder={useTranslate("Correo electrónico")}
            textContentType="emailAddress"
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="mail" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
          />
          <Button
            mb="2"
            onPress={onResetPassword.bind(this)}
            isLoading={isLoading}
          >
            {useTranslate("Enviar código")}
          </Button>
          <Button
            colorScheme="black"
            variant="link"
            mb="2"
            onPress={goToCodePage}
            isLoading={isLoading}
          >
            {useTranslate("Tiene código de recuperación?")}
          </Button>
          <AlertInfo />
        </View>
      </View>
    </View>
  );
}

function AlertInfo() {
  return (
    <Center>
      <Alert w="100%" backgroundColor={"warning.500"}>
        <VStack space={2} flexShrink={1} w="100%">
          <Box
            _text={{
              color: "white",
            }}
          >
            {useTranslate(
              "Te enviaremos un correo con las instrucciones para recuperar tu contraseña"
            ) + "."}
          </Box>
        </VStack>
      </Alert>
    </Center>
  );
}

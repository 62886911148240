import * as React from "react";
import { StyleSheet, Dimensions } from "react-native";
import { Text, View } from "native-base";
import { AudioGuide, Place } from "../types-graphql";

function GuideMap({ guide }: { guide: AudioGuide }) {
  const [route, setRoute] = React.useState();

  const mapRef = React.useRef(null);

  React.useEffect(() => {
    setRoute(JSON.parse(guide?.route != null ?  guide?.route : "null"));

   

  }, [guide]);

  function mapCoordinates(coordinates) {
    return coordinates.map((c) => {
      return { latitude: c[1], longitude: c[0] };
    });

  }

  const onMapReady = () => {
    mapRef?.current?.fitToCoordinates(
      guide.places.map((p) => {
        return { latitude: p.latitude, longitude: p.longitude };
      }),
      {
        edgePadding: { top: 50, right: 30, bottom: 20, left: 30 },
        animated: true,
      }
    );
  };


  return (
    <View></View>
  );
}

const styles = StyleSheet.create({
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height / 3,
  },
});

export default GuideMap;

import {
  SAVE_USER,
  UPDATE_PREFERRED_LANG_USER,
  LOGOUT,
  UPDATE_IMAGE_MODAL,
  UPDATE_STATUS_PLAY_AUDIOGUIDE
} from './actions';
import { Customer as User, Lang } from '../types-graphql.d';

export const AUDIO = 'audio';
export const VIDEO = 'video';

export type StautsPlayAudioGuide = {
  id: string,
  typeResource: typeof AUDIO | typeof VIDEO,
  position: number,
  placeId: string,
  finished: boolean,
  lang: string
}

const initialState = {
  user: undefined as User | undefined,
  statusPlayAudioGuide: [] as StautsPlayAudioGuide[],
  preferredLang: undefined as Lang | undefined,
};

const initialStateImage = {
  srcImageModal: undefined as String | undefined
};

export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case SAVE_USER:
      return { ...state, user: action.payload };
    case UPDATE_PREFERRED_LANG_USER:
      return { ...state, preferredLang: action.payload };
    case LOGOUT:
      return state = initialState;
    case UPDATE_STATUS_PLAY_AUDIOGUIDE:
      const index = state.statusPlayAudioGuide.findIndex(item => item.id === action.payload.id);
      if (index === -1) {
        state.statusPlayAudioGuide.push(action.payload);
      } else {
        state.statusPlayAudioGuide[index] = action.payload;
      }
      return { ...state, statusPlayAudioGuide: [...state.statusPlayAudioGuide] };     
    default:
      return state;
  }
}

export function imageModalReducer(state = initialStateImage, action: any) {
  switch (action.type) {
    case UPDATE_IMAGE_MODAL:
      return { ...state, srcImageModal: action.payload };
    default:
      return state;
  }
}

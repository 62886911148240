import {
  useCategoriesQuery,
  Category,
  useCitiesQuery,
  City,
} from "../types-graphql.d";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  Pressable,
  Modal,
  Radio,
} from "native-base";
import React, { useEffect, useState } from "react";
import { View } from "./Themed";
import useTranslate from "../hooks/useTranslate";

function SearchModal({ searchModal, setFilterProperties }) {
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState("0");
  const [city, setCity] = useState("0");
  const [categories, setCategories] = useState([] as Category[]);
  const [cities, setCities] = useState([] as City[]);
  const { data } = useCategoriesQuery({ variables: { page: 1 } });
  const { data: citiesData } = useCitiesQuery({ variables: { page: 1 } });

  useEffect(() => {
    searchModal.current = init;
  }, []);

  useEffect(() => {
    setCategories(data?.categories?.data as Category[]);
  }, [data]);

  useEffect(() => {
    setCities(citiesData?.cities?.data as City[]);
  }, [citiesData]);

  async function init() {
    setShowModal(true);
  }

  function submit() {
    setFilterProperties(category, city);
    setShowModal(false);        
  }

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
      <Modal.Content maxWidth="md">
        <Modal.CloseButton />
        <Modal.Header>{useTranslate("Búsqueda avanzada")}</Modal.Header>
        <Modal.Body>
          <View>
            <Text color="primary.500" fontSize="lg" fontWeight="bold" py="2">
              {useTranslate("Categorías")}
            </Text>
            <Radio.Group
              name="category"
              value={category}
              onChange={(newCategory) => {
                setCategory(newCategory);
              }}
              accessibilityLabel={useTranslate("Categorías")}
            >
              <Stack
                direction={{
                  base: "column",
                }}
                alignItems={{
                  base: "flex-start",
                }}
                space={4}
                w="75%"
                maxW="300px"
              >
                <Radio
                  value={"0"}
                  colorScheme="primary"
                  size="sm"
                  my={1}
                  key={0}
                >
                  {useTranslate("Todas")}
                </Radio>
                {categories?.map((category: Category) => {
                  return (
                    <Radio
                      value={category.id}
                      colorScheme="primary"
                      size="sm"
                      my={1}
                      key={category.id}
                    >
                      {category?.name}
                    </Radio>
                  );
                })}
              </Stack>
            </Radio.Group>
          </View>
          <View>
            <Text color="primary.500" fontSize="lg" fontWeight="bold" py="2">
              {useTranslate("Localidades")}
            </Text>
            <Radio.Group
              name="city"
              value={city}
              onChange={(newCity) => {
                setCity(newCity);
              }}
              accessibilityLabel={useTranslate("Localidades")}
            >
              <Stack
                direction={{
                  base: "column",
                }}
                alignItems={{
                  base: "flex-start",
                }}
                space={4}
                w="75%"
                maxW="300px"
              >
                <Radio
                  value={"0"}
                  colorScheme="primary"
                  size="sm"
                  my={1}
                  key={0}
                >
                  {useTranslate("Todas")}
                </Radio>
                {cities?.map((city: City) => {
                  return (
                    <Radio
                      value={city.id}
                      colorScheme="primary"
                      size="sm"
                      my={1}
                      key={city.id}
                    >
                      {city?.name}
                    </Radio>
                  );
                })}
              </Stack>
            </Radio.Group>
          </View>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              variant="ghost"
              colorScheme="blueGray"
              onPress={() => {
                setShowModal(false);
              }}
            >
              {useTranslate("Cancelar")}
            </Button>
            <Button onPress={submit}>{useTranslate("Buscar")}</Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export default SearchModal;

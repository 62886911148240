import { useResetPasswordMutation } from "../../types-graphql.d";
import { MaterialIcons } from "@expo/vector-icons";
import {
  Alert,
  Box,
  Button,
  Center,
  Heading,
  Icon,
  Image,
  Input,
  Text,
  View,
  VStack,
} from "native-base";
import React from "react";

import { RootStackScreenProps } from "../../types";
import useErrorHandler from "../../hooks/useErrorHandler";
import useTranslate from "../../hooks/useTranslate";

export default function NewPasswordScreen({
  navigation,
}: RootStackScreenProps<"NewPassword">) {
  const [token, onChangeToken] = React.useState("");
  const [email, onChangeEmail] = React.useState("");
  const [password, onChangePassword] = React.useState("");
  const [passwordConfirmation, onChangePasswordConfirmation] =
    React.useState("");
  const [showPassword, setshowPassword] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [resetPasswordMutation] = useResetPasswordMutation();
  const { handle: handleError } = useErrorHandler();

  const onResetPassword = async () => {
    try {
      setisLoading(true);
      const data = await resetPasswordMutation({
        variables: {
          input: {
            token,
            email,
            password,
            password_confirmation: passwordConfirmation,
          },
        },
      });
      if (data.data.resetPassword) {
        onChangeEmail("");
        navigation.navigate("Login");
      }
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <View
      bgColor="white"
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View maxWidth={"md"} width={"100%"} flex="1" pt="16">
        <View style={{ alignItems: "center" }}>
          <Heading color={"primary.500"} mb="4">
            {useTranslate("Nueva contraseña")}
          </Heading>
        </View>
        <View>
          <Input
            value={email}
            onChangeText={onChangeEmail}
            placeholder={useTranslate("Correo electrónico")}
            textContentType="emailAddress"
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="mail" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
          />
          <Input
            value={token}
            onChangeText={onChangeToken}
            placeholder={useTranslate("Código de recuperación")}
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="vpn-key" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
          />
          <Input
            value={password}
            onChangeText={onChangePassword}
            placeholder={useTranslate("Contraseña")}
            secureTextEntry={true}
            textContentType="password"
            type={showPassword ? "text" : "password"}
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="lock" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
            InputRightElement={
              <Icon
                as={
                  <MaterialIcons
                    name={showPassword ? "visibility" : "visibility-off"}
                  />
                }
                size={5}
                mr="2"
                color="muted.200"
                onPress={() => setshowPassword(!showPassword)}
              />
            }
          />
          <Input
            value={passwordConfirmation}
            onChangeText={onChangePasswordConfirmation}
            placeholder={useTranslate("Confirmación contraseña")}
            secureTextEntry={true}
            textContentType="password"
            type={showPassword ? "text" : "password"}
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="lock" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
            InputRightElement={
              <Icon
                as={
                  <MaterialIcons
                    name={showPassword ? "visibility" : "visibility-off"}
                  />
                }
                size={5}
                mr="2"
                color="muted.200"
                onPress={() => setshowPassword(!showPassword)}
              />
            }
          />
          <Button onPress={onResetPassword.bind(this)} isLoading={isLoading}>
            {useTranslate("Recuperar")}
          </Button>
        </View>
      </View>
    </View>
  );
}

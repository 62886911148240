const tintColorLight = '#1993D9';
const tintColorDark = '#fff';

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    border: '#e2e2e2',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    anchorBackground: 'rgba(187, 239, 253, 0.3)',
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    border: '#fff',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    anchorBackground: 'rgba(97, 218, 251, 0.12)',
  },
};

import { useContext } from 'react';
import { formatDuration, intervalToDuration, Locale } from "date-fns";
import { es, ca, enGB, fr } from "date-fns/locale";
import { LanguagesTypes, LocalizationContext } from '../i18n/LocalizationProvider';

export default function useGetDuration(
  duration: number
): string {

  const i18n = useContext<LocalizationContext>(LocalizationContext);

  const locale = i18n.getLocale();

  function getLocaleLibrary(locale: string): Locale {
    switch (locale) {
      case LanguagesTypes.es:
        return es;
      case LanguagesTypes.ca:
        return ca;
      case LanguagesTypes.en:
        return enGB;
      case LanguagesTypes.fr:
        return fr;
      default:
        return enGB;
    }
  }

  function getLocaleDelimiter(locale: string): string {
    switch (locale) {
      case LanguagesTypes.es:
        return " y ";
      case LanguagesTypes.ca:
        return " i ";
      case LanguagesTypes.en:
        return " and ";
      case LanguagesTypes.fr:
        return " et ";
      default:
        return " and ";
    }
  }

  function getDuration(minutes: number): string {
    const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 });
    return formatDuration(duration, { locale: getLocaleLibrary(locale), delimiter: getLocaleDelimiter(locale) });
  }

  return getDuration(duration)
}
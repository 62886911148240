import {
  Avatar,
  Box,
  Button,
  Center,
  Icon,
  ScrollView,
  Spinner,
  Text,
  View,
  VStack,
} from "native-base";
import { SafeAreaView, StyleSheet } from "react-native";
import { useDispatch } from "react-redux";

import { logout } from "../redux/actions";
import { RootStackParamList, RootTabScreenProps } from "@/types";
import { useLogoutMutation, useMeQuery } from "../types-graphql.d";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Footer from "../components/Footer";
import AppVersion from "../components/AppVersion";
import useErrorHandler from "../hooks/useErrorHandler";
import { useEffect, useState } from "react";
import useTranslate from "../hooks/useTranslate";
import { WITH_CONTENT } from "../constants/Layout";

export default function ProfileScreen({
  navigation,
}: RootTabScreenProps<"Profile">) {
  const [isLoading, setIsLoading] = useState(true);
  const { data, loading, error } = useMeQuery({ fetchPolicy: "network-only" });
  const { handle: handleError } = useErrorHandler();

  const [logoutMutation, { loading: logoutLoading, error: errorLogout }] =
    useLogoutMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  useEffect(() => {
    if (errorLogout) {
      handleError(errorLogout);
    }
  }, [errorLogout]);

  const onLogout = async () => {
    const data = await logoutMutation();
    dispatch(logout());
    navigation.reset({
      index: 0,
      routes: [{ name: "Root" }],
    });
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  if (isLoading) {
    return (
      <Box flex={1} justifyContent="center" alignItems={"center"} safeArea>
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <ScrollView>
      <View flex="1" alignItems="center" width={"100%"}>
        <View maxWidth={"md"} width={"100%"} flex="1">
          <View
            flex="1"
            alignItems={"center"}
            justifyContent={"center"}
            mt={10}
          >
            <Avatar size="xl" bg="primary.500" mb={2}>
              {data?.me?.name?.charAt(0).toUpperCase() +
                "" +
                data?.me?.lastName?.charAt(0).toUpperCase()}
            </Avatar>

            <Text fontSize="lg" fontWeight={"bold"}>
              {data?.me?.name} {data?.me?.lastName}
            </Text>
            <Text>{data?.me?.email}</Text>
          </View>

          <VStack flex={1} space={2} mt={"5"}>
            <Button
              size="lg"
              variant="subtle"
              onPress={() => navigation.navigate("EditProfile")}
              endIcon={<IconButton name="account" />}
            >
              {useTranslate("Editar perfil")}
            </Button>
            <Button
              size="lg"
              variant="subtle"
              onPress={() => navigation.navigate("Facturation")}
              endIcon={<IconButton name="account-cash" />}
            >
              {useTranslate("Datos de facturación")}
            </Button>
            <Button
              size="lg"
              variant="subtle"
              onPress={() => navigation.navigate("Info")}
              endIcon={<IconButton name="information-outline" />}
            >
              {useTranslate("Información")}
            </Button>
            <Button
              size="lg"
              variant="subtle"
              onPress={() => navigation.navigate("LegalWarning")}
              endIcon={<IconButton name="book-open-blank-variant" />}
            >
              {useTranslate("Aviso Legal")}
            </Button>
            <Button
              size="lg"
              variant="subtle"
              onPress={() => navigation.navigate("SellConditions")}
              endIcon={<IconButton name="shield-alert" />}
            >
              {useTranslate("Condiciones de venta")}
            </Button>
            <Button
              size="lg"
              variant="subtle"
              onPress={() => navigation.navigate("Privacy")}
              endIcon={<IconButton name="shield-alert" />}
            >
              {useTranslate("Privacidad")}
            </Button>
            <Button
              size="lg"
              variant="link"
              colorScheme={"danger"}
              onPress={onLogout}
              isLoading={logoutLoading}
              endIcon={<IconButton name="logout" />}
            >
              {useTranslate("Cerrar sesión")}
            </Button>
            <AppVersion />
          </VStack>
        </View>
      </View>
    </ScrollView>
  );
}

function IconButton(props: {
  name: React.ComponentProps<typeof MaterialCommunityIcons>["name"];
}) {
  return <Icon as={MaterialCommunityIcons} size="sm" {...props} />;
}

import { Text, Modal } from "native-base";
import React, { useEffect, useState } from "react";
import { View } from "./Themed";
import useTranslate from "../hooks/useTranslate";
import { useDynamicPageQuery } from "../types-graphql.d";
import { DynamicPages } from "../types/DynamicPages";
import RenderHTML, { RenderHTMLSource } from "react-native-render-html";

function SellConditionsModal({ sellConditionsModal }) {
  const [showModal, setShowModal] = useState(false);
  const { data, loading } = useDynamicPageQuery({
    variables: { type: DynamicPages.SELL_CONDITIONS },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    sellConditionsModal.current = init;
  }, []);

  async function init() {
    setShowModal(true);
  }

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
      <Modal.Content maxWidth="700px">
        <Modal.CloseButton />
        <Modal.Header>{useTranslate("Condiciones de venta")}</Modal.Header>
        <Modal.Body>
          <View>
            <RenderHTML
              contentWidth={700}
              baseStyle={{ fontSize: 14, lineHeight: 20, backgroundColor: "rgb(250, 250, 250)"}}
              source={{ html: data?.dynamicPage.content as string }}
            />
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

export default SellConditionsModal;

import { Box, Center, Heading, View } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";
import useTranslate from "../hooks/useTranslate";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_SECRET } from "@env";
import CheckoutForm from "../components/CheckoutForm";

export default function StripeScreen({ route }: { route: any }) {
  const clientSecret = route.params.clientSecret;
  const audioGuideId = route.params.audioGuideId;

  console.log("clientSecret", clientSecret);

  console.log("STRIPE_SECRET", STRIPE_SECRET);

  const stripePromise = loadStripe(STRIPE_SECRET);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <Center>
      <View maxWidth={"750px"} width={"100%"}>
        <Heading size="md" color="primary.500" px="6" py="2">
          {useTranslate("Datos de la tarjeta")}
        </Heading>
        <Box px="6">
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        </Box>
      </View>
    </Center>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

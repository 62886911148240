export const SAVE_USER = 'SAVE_USER';
export const UPDATE_PREFERRED_LANG_USER = 'UPDATE_PREFERRED_LANG_USER';
export const LOGOUT = 'LOGOUT';
export const UPDATE_IMAGE_MODAL = 'UPDATE_IMAGE_MODAL';
export const UPDATE_STATUS_PLAY_AUDIOGUIDE = 'UPDATE_STATUS_PLAY_AUDIOGUIDE';
import {Dispatch} from 'redux';
import { Customer as User, Lang } from '../types-graphql.d';
import { StautsPlayAudioGuide } from './reducers';

export const saveUser = (user: User) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_USER,
      payload: user,
    });
  };
};

export const updatePreferredLangUser = (preferredLang: Lang) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_PREFERRED_LANG_USER,
      payload: preferredLang,
    });
  };
};

export const logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};

export const updateStatusPlayAudioguide = (statusPlayAudioGuide: StautsPlayAudioGuide) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_STATUS_PLAY_AUDIOGUIDE,
      payload: statusPlayAudioGuide,
    });
  };
};

export const updateImageModal = (srcImage: String) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_IMAGE_MODAL,
      payload: srcImage,
    });
  };
};

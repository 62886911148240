/* eslint-disable eqeqeq */
import {
  MaintenanceMode,
  NetworkRequestFailed,
  translateError,
  Unauthenticated,
  ValidationField,
  VersionError,
} from '../errors/errors';
import { ServiceBundleType } from '../errors/Wall_e';
import { ApolloError } from '@apollo/client/errors';
import { Wall_e } from '@markdomkan/wall_e';

export default function registerErrors(
  walle: Wall_e<ServiceBundleType>,
): Wall_e<ServiceBundleType> {
  return walle
    .register((error: ApolloError) =>
      error.graphQLErrors?.some(
        gqlError => gqlError.message === 'Unauthenticated.',
      )
        ? new Unauthenticated()
        : undefined,
    )
    .register(error =>
      error.message == 401 ? new Unauthenticated() : undefined,
    )
    .register(error => {
      if (error === 'Unauthenticated.') {
        return new Unauthenticated();
      }
    })
    .register(error => {
      if (error.message === 'Unauthenticated.') {
        return new Unauthenticated();
      }
    })
    .register(error =>
      error.networkError?.statusCode == 503 ? new MaintenanceMode() : undefined,
    )
    .register(error => {
      if (error.message == 'App version must be updated') {
        const UserError = new VersionError();
        UserError.message = 'Update version';
        return UserError;
      }
    })    
    .register(error => {
      if (
        /(?:^|\W)Validation failed for the field(?:$|\W)/.test(
          error.message,
        )
      ) {
        const UserError = new ValidationField();
        UserError.message = '';
        for (const key in error.graphQLErrors[0].extensions.validation) {
          if (
            error.graphQLErrors[0].extensions.validation.hasOwnProperty(key)
          ) {
            UserError.message = `${UserError.message}${translateError(
              error.graphQLErrors[0].extensions.validation[key][0],
            )}\n`;
          }
        }
        return UserError;
      }
    })
    .register(error => {
      if (
        /(?:^|\W)The given data was invalid.(?:$|\W)/.test(
          error.message,
        )
      ) {
        const UserError = new ValidationField();
        UserError.message = '';
        for (const key in error.graphQLErrors[0].extensions.validation) {
          if (
            error.graphQLErrors[0].extensions.validation.hasOwnProperty(key)
          ) {
            UserError.message = `${UserError.message}${translateError(
              error.graphQLErrors[0].extensions.validation[key][0],
            )}\n`;
          }
        }
        return UserError;
      }
    })
    .register(error => {
      if (error.message) {
        const UserError = new ValidationField();
        UserError.message = translateError(error.message);
        return UserError;
      }
    })
    .register(error =>
      error.networkError?.message === 'Network request failed'
        ? new NetworkRequestFailed()
        : undefined,
    );
}

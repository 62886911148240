import { useNavigation } from "@react-navigation/native";
import { formatDuration, intervalToDuration } from "date-fns";
import { es, ca } from "date-fns/locale";
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  Pressable,
} from "native-base";
import React from "react";
import useGetDuration from "../hooks/useGetDuration";
import useTranslate from "../hooks/useTranslate";
import { AudioGuide } from "../types-graphql.d";

function GuideCard({ guide }: { guide: AudioGuide }) {
  const navigation = useNavigation();

  function goToDetail() {
    navigation.navigate("GuideDetail", { guideId: guide.id });
  }

  return (
    <Pressable onPress={goToDetail}>
      <Box
        rounded="lg"
        overflow="hidden"
        borderColor="coolGray.200"
        borderWidth="1"
        mb={"2"}
        _light={{
          backgroundColor: "gray.50",
        }}
      >
        <Box>
          <Image
            source={{
              uri: guide.imageUrl,
            }}
            w="100%"
            h="48"
            alt="image"
          />
          <Center
            bg="primary.500"
            borderTopRightRadius={"md"}
            position="absolute"
            bottom="0"
            px="3"
            py="1.5"
          >
            <Text color="yellow.400" fontWeight="bold" fontSize="md">
              {guide?.price} €
            </Text>
          </Center>
          <Center
            bg="white"
            _text={{
              color: "primary.400",
              fontWeight: "bold",
              fontSize: "md",
            }}
            borderTopLeftRadius={"md"}
            position="absolute"
            bottom="0"
            right="0"
            px="3"
            py="1.5"
          >
            {useGetDuration(guide.duration)}
          </Center>
        </Box>
        <Stack p="4" space={3}>
          <Heading size="md">{guide.title}</Heading>
          <Stack>
            <Center>
              <Button w="sm" bg="primary.500" onPress={goToDetail}>
                {useTranslate("Reservar")}
              </Button>
            </Center>
          </Stack>
        </Stack>
      </Box>
    </Pressable>
  );
}

export default GuideCard;

import { Image as ImageType } from "../types-graphql.d";
import React, { useRef, useState } from "react";
import { Box, Button, Flex, HStack, Image, ScrollView, View } from "native-base";
import { StyleSheet, useWindowDimensions } from "react-native";

function Gallery({ images }: { images: ImageType[] }) {
  const { width: AppWidth } = useWindowDimensions();

  const [offset, setOffset] = useState(0);

  const currentDotIndex = () => {
    return Math.round(
      (images.length || 0) -
        (AppWidth * (images.length || 0) - offset) / AppWidth
    );
  };

  const scrollRef = useRef();

  const onPressToRight = () => {
    scrollRef.current?.scrollTo({
      x: offset + AppWidth,
      animated: true,
    });
  };
  const onPressToLeft = () => {
    scrollRef.current?.scrollTo({
      x: offset - AppWidth,
      animated: true,
    });
  };

  return (
    <View style={styles.container}>
      <ScrollView
        onScroll={(event) => setOffset(event?.nativeEvent?.contentOffset?.x)}
        ref={scrollRef}
        showsHorizontalScrollIndicator={false}
        pagingEnabled={true}
        scrollEventThrottle={AppWidth}
        horizontal={true}
        snapToAlignment="center"
        overScrollMode="auto"
        maxWidth={AppWidth}
      >
        {images &&
          images.map((image) => {
            return (
              <View key={image.id}>
                <Image
                  source={{
                    uri: image.url || "",
                  }}
                  w={AppWidth}
                  h="300"
                  alt="image"
                  key={image.id}
                />
              </View>
            );
          })}
      </ScrollView>
      <Flex  px="2" position="absolute" top="120px" width="100%" direction="row" justifyContent="space-between" alignContent={"space-between"}>
        <Button bg="primary.500" onPress={onPressToLeft} left="0" position="relative">{"<"}</Button>
        <Button bg="primary.500" onPress={onPressToRight} right="0" position="relative">{">"}</Button>
      </Flex >
      <View style={styles.dotsContainerStyle}>
        {images &&
          images.map((_, index) => {
            return (
              <View
                key={index}
                style={
                  index === currentDotIndex()
                    ? styles.highlightedDotStyle
                    : styles.normalDotStyle
                }
              />
            );
          })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "column",
  },
  dotsContainerStyle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  normalDotStyle: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: "#c2c2c2",
    margin: 3,
    opacity: 0.2,
  },
  highlightedDotStyle: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: "#c2c2c2",
    margin: 3,
    opacity: 1,
  },
});

export default Gallery;

import {
  Avatar,
  Box,
  Button,
  Heading,
  Icon,
  Input,
  ScrollView,
  Spinner,
  Text,
  View,
  VStack,
} from "native-base";
import { useDispatch } from "react-redux";

import { RootStackParamList, RootTabScreenProps } from "../../types";
import { useMeQuery, useUpdateInvoicingMutation } from "../../types-graphql.d";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import React, { useEffect } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { SafeAreaView } from "react-native";
import useTranslate from "../../hooks/useTranslate";

export default function FacturationScreen({
  navigation,
}: RootTabScreenProps<"Profile">) {
  const { data, loading } = useMeQuery();
  const [name, onChangeName] = React.useState("");
  const [lastName, onChangeLastName] = React.useState("");
  const [address, onChangeAddress] = React.useState("");
  const [city, onChangeCity] = React.useState("");
  const [postalCode, onChangePostalCode] = React.useState("");
  const [country, onChangeCountry] = React.useState("");
  const [dni, onChangeDni] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateInvoicingMutation] = useUpdateInvoicingMutation();
  const { handle: handleError } = useErrorHandler();

  const updateInvoicing = async () => {
    try {
      setIsLoading(true);
      console.log(name, lastName, address, postalCode, city, country, dni);
      const data = await updateInvoicingMutation({
        variables: {
          input: {
            name,
            lastName,
            address,
            postalCode,
            city,
            country,
            dni,
          },
        },
      });
      console.log(data.data?.updateInvoicing);
      onChangeName(data.data?.updateInvoicing.name || "");
      onChangeLastName(data.data?.updateInvoicing.lastName || "");
      onChangeAddress(data.data?.updateInvoicing.address || "");
      onChangeCity(data.data?.updateInvoicing.city || "");
      onChangePostalCode(data.data?.updateInvoicing.postalCode || "");
      onChangeCountry(data.data?.updateInvoicing.country || "");
      onChangeDni(data.data?.updateInvoicing.dni || "");
      navigation.reset({
        index: 0,
        routes: [{ name: "Root", params: { tab: "Profile" } }],
      });
    } catch (e) {
      console.log(e);
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      onChangeName(data?.me?.name || "");
      onChangeLastName(data?.me?.lastName || "");
      onChangeAddress(data?.me?.address || "");
      onChangeCity(data?.me?.city || "");
      onChangePostalCode(data?.me?.postalCode || "");
      onChangeCountry(data?.me?.country || "");
      onChangeDni(data?.me?.dni || "");
    }
  }, [data]);

  if (loading) {
    return (
      <Box flex={1} justifyContent="center" alignItems={"center"} safeArea>
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingHorizontal: 4 * 4,
        marginTop: 10 * 4,
      }}
    >
      <ScrollView>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View maxWidth={"md"} width={"100%"} mb="10">
            <Input
              value={name}
              onChangeText={onChangeName}
              placeholder={useTranslate("Nombre")}
              textContentType="name"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="person" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={lastName}
              onChangeText={onChangeLastName}
              placeholder={useTranslate("Apellidos")}
              textContentType="givenName"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="person" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={address}
              onChangeText={onChangeAddress}
              placeholder={useTranslate("Dirección postal")}
              textContentType="streetAddressLine1"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="home" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={city}
              onChangeText={onChangeCity}
              placeholder={useTranslate("Ciudad")}
              textContentType="addressCity"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="home" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={postalCode}
              onChangeText={onChangePostalCode}
              placeholder={useTranslate("Código postal")}
              textContentType="postalCode"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="home" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={country}
              onChangeText={onChangeCountry}
              placeholder={useTranslate("País")}
              textContentType="countryName"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="flag" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={dni}
              onChangeText={onChangeDni}
              placeholder={useTranslate("DNI")}
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="badge" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
          </View>
          <View maxWidth={"md"} width={"100%"}>
              <Button onPress={() => updateInvoicing()} isLoading={isLoading}>
                {useTranslate("Guardar")}
              </Button>
              <Button variant="subtle" onPress={() => navigation.goBack()}>
                {useTranslate("Cancelar")}
              </Button>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";
import ModalScreen from "../screens/ModalScreen";
import LoginScreen from "../screens/Auth/LoginScreen";
import ResetPasswordScreen from "../screens/Auth/ResetPasswordScreen";
import NewPasswordScreen from "../screens/Auth/NewPasswordScreen";
import HomeScreen from "../screens/HomeScreen";
import GuidesScreen from "../screens/GuidesScreen";
import ProfileScreen from "../screens/ProfileScreen";
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import { Icon, Input, View } from "native-base";
import GuideDetailScreen from "../screens/GuideDetailScreen";
import RegisterScreen from "../screens/Auth/RegisterScreen";
import { store } from "../redux/store";
import { Customer } from "../types-graphql.d";
import EditProfileScreen from "../screens/Profile/EditProfileScreen";
import FacturationScreen from "../screens/Profile/FacturationScreen";
import InfoScreen from "../screens/Profile/InfoScreen";
import OrderScreen from "../screens/OrderScreen";
import StripeScreen from "../screens/StripeScreen";
import ImageScreen from "../screens/ImageScreen";
import Wall_eProvider from '../errors/Wall_e';
import useTranslate from "../hooks/useTranslate";
import LegalWarningScreen from "../screens/Profile/LegalWarningScreen";
import PrivacyScreen from "../screens/Profile/PrivacyScreen";
import VersionScreen from "../screens/VersionScreen";
import MaintenanceScreen from "../screens/MaintenanceScreen";
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import SellConditionsScreen from "../screens/Profile/SellConditionsScreen";
import ReviewsScreen from "../screens/ReviewsScreen";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
    <Wall_eProvider>
      <RootNavigator />
    </Wall_eProvider>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const user: Customer | undefined = store.getState().userReducer.user;
  const initialPage = "Root"// !!user?.token ? "Root" : "Login";
  return (
    <Stack.Navigator initialRouteName={initialPage}>
      <Stack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false, orientation: "portrait" }}
      />
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{ headerShown: false, orientation: "portrait" }}
      />
      <Stack.Group
        screenOptions={{
          headerShown: true,
          title: "",
          orientation: "portrait",
        }}
      >
        <Stack.Screen name="Register" component={RegisterScreen} />
        <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
        <Stack.Screen name="NewPassword" component={NewPasswordScreen} />
      </Stack.Group>
      <Stack.Group>
        <Stack.Screen
          options={{ title: " ", orientation: "portrait" }}
          name="ImageModal"
          component={ImageScreen}
        />
        <Stack.Screen
          options={{ title: " ", orientation: "portrait" }}
          name="GuideDetail"
          component={GuideDetailScreen}
        />
        <Stack.Screen
          options={{ title: useTranslate("Valoraciones"), orientation: "portrait" }}
          name="Reviews"
          component={ReviewsScreen}
        />
        <Stack.Screen
          options={{ title: useTranslate("Pedido"), orientation: "portrait" }}
          name="Order"
          component={OrderScreen}
        />
        <Stack.Screen
          options={{ title: useTranslate("Confirmar pago"), orientation: "portrait" }}
          name="Stripe"
          component={StripeScreen}
        />
        <Stack.Screen
          options={{ title: " ", orientation: "portrait" }}
          name="Version"
          component={VersionScreen}
        />
        <Stack.Screen
          options={{ title: " ", orientation: "portrait" }}
          name="Maintenance"
          component={MaintenanceScreen}
        />
      </Stack.Group>
      <Stack.Group>
        <Stack.Screen
          options={{ title: useTranslate("Editar perfil"), orientation: "portrait" }}
          name="EditProfile"
          component={EditProfileScreen}
        />
        <Stack.Screen
          options={{ title: useTranslate("Datos de facturación"), orientation: "portrait" }}
          name="Facturation"
          component={FacturationScreen}
        />
        <Stack.Screen
          options={{ title: useTranslate("Información"), orientation: "portrait" }}
          name="Info"
          component={InfoScreen}
        />
        <Stack.Screen
          options={{ title: useTranslate("Aviso Legal"), orientation: "portrait" }}
          name="LegalWarning"
          component={LegalWarningScreen}
        />
        <Stack.Screen
          options={{ title: useTranslate("Privacidad"), orientation: "portrait" }}
          name="Privacy"
          component={PrivacyScreen}
        />
        <Stack.Screen
          options={{
            title: useTranslate("Condiciones de venta"),
            orientation: "portrait",
          }}
          name="SellConditions"
          component={SellConditionsScreen}
        />
      </Stack.Group>

      <Stack.Group
        screenOptions={{ presentation: "modal", orientation: "portrait" }}
      >
        {/* TODO permissions and mantencane pages */}
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
//const BottomTab = createBottomTabNavigator<RootTabParamList>();
const BottomTab = createMaterialTopTabNavigator<RootTabParamList>();

function BottomTabNavigator({route}) {
  const initialTabName = route.params?.tab ?? "Home";

  return (
    <BottomTab.Navigator
      initialRouteName={initialTabName}
      screenOptions={{
        tabBarActiveTintColor: "#1993D9",
        tabBarIndicatorStyle:{ backgroundColor: "#1993D9" }
      }}

      lazy={true}
      swipeEnabled={false}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }: RootTabScreenProps<"Home">) => ({
          title: useTranslate("Buscar"),
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="magnify" color={color} />,
        })}
      />
      <BottomTab.Screen
        name="Guides"
        component={GuidesScreen}
        options={{
          title: useTranslate("Mis Guías"),
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="map" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: useTranslate("Perfil"),
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="account" color={color} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof MaterialCommunityIcons>["name"];
  color: string;
}) {
  return (
    <MaterialCommunityIcons size={30} style={{ marginBottom: -3 }} {...props} />
  );
}

import {
  AudioGuide,
  Image as ImageType,
  useAudioGuideQuery,
} from "../types-graphql.d";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  View,
} from "native-base";
import {
  StyleSheet,
  useWindowDimensions,
  Text as TextNative,
} from "react-native";
import Gallery from "../components/Gallery";
import { useContext, useEffect, useState } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import GuideMap from "../components/GuideMap";
import ReviewStars from "../components/ReviewStars";
import useGetDuration from "../hooks/useGetDuration";
import useTranslate from "../hooks/useTranslate";
import { LocalizationContext } from "../i18n/LocalizationProvider";
import { RenderHTMLSource } from "react-native-render-html";
import useErrorHandler from "../hooks/useErrorHandler";
import { WITH_CONTENT } from "../constants/Layout";

export default function GuideDetailScreen({ route }) {
  const audioGuideId = route.params.guideId;
  const i18n = useContext<LocalizationContext>(LocalizationContext);
  const { handle: handleError } = useErrorHandler();
  const { data, loading, error } = useAudioGuideQuery({
    fetchPolicy: "network-only",
    variables: {
      id: audioGuideId,
      lang: i18n.getLocale(),
    },
  });

  const [audioGuide, setAudioGuide] = useState(data?.audioGuide as AudioGuide);
  const navigation = useNavigation();
  navigation.setOptions({ headerTitle: data?.audioGuide?.title });
  const { width: AppWidth } = useWindowDimensions();

  useEffect(() => {
    setAudioGuide(data?.audioGuide as AudioGuide);
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  function goToOrder() {
    navigation.navigate("Order", { guideId: audioGuide.id });
  }

  function goToReviews() {
    navigation.navigate("Reviews", { guideId: audioGuide.id });
  }

  if (loading) {
    return (
      <Box flex={1} justifyContent="center" alignItems={"center"} safeArea>
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <Center>
      <View maxWidth={WITH_CONTENT} width={"100%"}>
        <ScrollView contentContainerStyle={styles.container}>
          <Box>
            {audioGuide?.imageUrl && (
              <Image
                source={{
                  uri: audioGuide?.imageUrl || "",
                }}
                w={AppWidth}
                h="200"
                alt="image"
              />
            )}
            <Center
              position="absolute"
              textAlign="center"
              justifyContent="center"
              width="100%"
              bottom="0"
              px="3"
              pb="10"
              display="flex"
              shadow="9"
            >
              <TextNative
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 18,
                  textShadowColor: "rgb(0, 0, 0)",
                  textShadowOffset: { width: 0, height: 0 },
                  textShadowRadius: 10,
                }}
              >
                {audioGuide?.title}
              </TextNative>
            </Center>
            <Center
              bg="primary.500"
              borderTopRightRadius={"md"}
              position="absolute"
              bottom="0"
              px="3"
              py="1.5"
            >
              <Text color="yellow.400" fontWeight="bold" fontSize="md">
                {audioGuide?.price} €
              </Text>
            </Center>
          </Box>
          <Box pt="4" px="4">
            <View flexDir={"row"} width="full" justifyContent="space-between">
              <Box>
                <Text color="primary.500" fontWeight="bold" fontSize="md">
                  <Icon
                    as={<MaterialCommunityIcons name="av-timer" />}
                    size={6}
                    color="primary.500"
                  />
                  {useTranslate("Duración")}
                </Text>
                <Text>{useGetDuration(audioGuide?.duration || 0)}</Text>
                <Box py="2" mx="-2" flex={1} flexDir="row">
                  <Pressable onPress={goToReviews}>
                    <ReviewStars rate={audioGuide?.averageRating} />
                  </Pressable>
                  <Button
                    my="-4"
                    size="lg"
                    variant="link"
                    onPress={goToReviews}
                  >
                    {useTranslate("Ver las valoraciones")}
                  </Button>
                </Box>
              </Box>
              <Box>
                <Button
                  h="auto"
                  size={"md"}
                  bg="primary.500"
                  onPress={() => {
                    goToOrder();
                  }}
                >
                  {useTranslate("Comprar")}
                </Button>
              </Box>
            </View>
            <RenderHTMLSource
              contentWidth={AppWidth}
              source={{ html: audioGuide?.description as string }}
            />
          </Box>

          <View py={"2"}>
            <Gallery images={audioGuide?.images as ImageType[]} />
          </View>

          <View py={"5"}>{audioGuide && <GuideMap guide={audioGuide} />}</View>
        </ScrollView>
      </View>
    </Center>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
  },
});

import React, { createContext, useContext, useEffect, useState } from "react";
import * as Localization from "expo-localization";
import i18n from "i18n-js";
import { store } from "../redux/store";
import ca from './ca.json';
import es from './es.json';
import en from './en.json';
import fr from './fr.json';
import { useLanguagesQuery } from "../types-graphql.d";

export type LocalizationContext = {
  t(text: string): string;
  getLocale(): string;
};

export const LocalizationContext = createContext<LocalizationContext>({
  t: (text) => "",
  getLocale: () => "",
});

export const LanguagesTypes = {
  ca: "ca",
  es: "es",
  en: "en",
  fr: "fr",
};

export default (props: { children?: React.ReactNode }) => {
  const [languageStored, setLanguagedStored] = useState(
    getStorageLanguage(store.getState()) as string | undefined
  );

  const { data: dataLanguages, loading: loadingLanguages } = useLanguagesQuery({
    fetchPolicy: "network-only",
  });

  function getLocale(): string {
    const locale = languageStored ?? Localization.locale.split("-")[0];
    let isEnabled = false;
    dataLanguages?.languages.forEach((language) => {
      if (language.code == locale){
        isEnabled = true;
      }
    });

    let english = dataLanguages?.languages.find(language => language.code == LanguagesTypes.en)

    if (!isEnabled) {
      return english!== undefined ? LanguagesTypes.en : LanguagesTypes.es;
    }
    
    switch (locale) {
      case LanguagesTypes.es:
        return LanguagesTypes.es;
      case LanguagesTypes.ca:
        return LanguagesTypes.ca;
      case LanguagesTypes.en:
        return LanguagesTypes.en;
      case LanguagesTypes.fr:
        return LanguagesTypes.fr;
      default:
        return LanguagesTypes.en;
    }
  }

  function getStorageLanguage(state: any): string | undefined {
    return state.userReducer.preferredLang?.code;
  }

  function storeUpdated() {
    const newLanguageStored = getStorageLanguage(store.getState());

    if (languageStored !== newLanguageStored) {
      setLanguagedStored(newLanguageStored);
    }
  }
  store.subscribe(storeUpdated); 
  
  // Set the key-value pairs for the different languages you want to support.
  i18n.translations = {
    en: en,
    es: es,
    ca: ca,
    fr: fr
  };

  i18n.locale = getLocale();
  
  // When a value is missing from a language it'll fallback to another language with the key present.
  i18n.fallbacks = true;

  function t(text: string): string {
    return i18n.t(text);
  }

  return (
    <LocalizationContext.Provider
      value={{
        t: (text: string) => t(text),
        getLocale: () => getLocale(),
      }}
    >
      {props.children}
    </LocalizationContext.Provider>
  );
};

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { StatusBar } from "expo-status-bar";
import { Box, extendTheme, NativeBaseProvider } from "native-base";
import useCachedResources from "./hooks/useCachedResources";
import Navigation from "./navigation";
import { Customer } from "./types-graphql";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import LocalizationProvider from "./i18n/LocalizationProvider";
import HTMLEngine from "./components/HTMLEngine";
import { API_URL, STRIPE_SECRET, VERSION } from "@env";

export default function App() {
  const isLoadingComplete = useCachedResources();

  console.log("API_URL", API_URL);

  //force light mode for now
  //TODO: s'han de repasar totes les pantalles en modo dark
  const colorScheme = "light"; //useColorScheme();

  const theme = extendTheme({
    colors: {
      // Add new color
      primary: {
        50: "#E8F5FC",
        100: "#D1EBFA",
        200: "#9FD5F4",
        300: "#71C1EF",
        400: "#3FABE9",
        500: "#1993D9",
        600: "#1475AE",
        700: "#0F5A85",
        800: "#0A3B57",
        900: "#051F2E",
      },
      secondary: {
        50: "#F9EFE6",
        100: "#F4DFCD",
        200: "#E8BF9B",
        300: "#DD9F6A",
        400: "#D17F38",
        500: "#A66226",
        600: "#854E1E",
        700: "#643B17",
        800: "#42270F",
        900: "#211408",
      },
      // Redefinig only one shade, rest of the color will remain same.
      amber: {
        400: "#d97706",
      },
    },
    components: {
      Button: {
        // Can simply pass default props to change default behaviour of components.
        baseStyle: {
          rounded: "md",
        },
        defaultProps: {
          colorScheme: "primary",
        },
      },
      Input: {
        defaultProps: {
          colorScheme: "primary",
          size: "lg",
        },
      },
    },
    config: {
      // Changing initialColorMode to 'dark'
      initialColorMode: "light",
    },
  });

  const httpLink = createHttpLink({
    uri: API_URL,
  });

  const authLink = setContext((_, { headers }) => {
    const user: Customer | undefined = store.getState().userReducer.user;
    const token = user?.token;
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
        "App-Version": VERSION,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const publishableKey = STRIPE_SECRET;

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloProvider client={client}>
            <LocalizationProvider>
              <NativeBaseProvider theme={theme}>
                <HTMLEngine>
                  <Box flex={1} safeArea>
                    <Navigation colorScheme={colorScheme} />
                    <StatusBar />
                  </Box>
                </HTMLEngine>
              </NativeBaseProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </PersistGate>
      </ReduxProvider>
    );
  }
}

import { combineReducers, applyMiddleware, legacy_createStore } from 'redux';
import thunk from 'redux-thunk';
import { userReducer, imageModalReducer } from './reducers';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistUserConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['user', 'statusPlayAudioGuide', 'preferredLang'],
};

const persistImageConfig = {
  key: 'image',
  storage: AsyncStorage,
  whitelist: ['srcImageModal'],
};

const rootReducer = combineReducers({
  userReducer: persistReducer(persistUserConfig, userReducer),
  imageModalReducer: persistReducer(persistImageConfig, imageModalReducer)
});

export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

import { FlatList, Box, Spinner } from "native-base";
import React, { useEffect, useState } from "react";
import EmptyState from "../components/EmptyState";
import MyGuideCard from "../components/MyGuideCard";
import useErrorHandler from "../hooks/useErrorHandler";
import useTranslate from "../hooks/useTranslate";
import { useMyAudioGuidesQuery } from "../types-graphql.d";

export default function GuidesScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const { data, loading, refetch, error } = useMyAudioGuidesQuery({
    fetchPolicy: "network-only",
  });
  const { handle: handleError } = useErrorHandler();

  useEffect(() => {
    if (error) {
      handleError(error);
      console.log("error", error);
    }
  }, [error]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const renderItem = ({ item: guide }: { item: Object }) => (
    <MyGuideCard guide={guide} />
  );

  if (isLoading) {
    return (
      <Box flex={1} justifyContent="center" alignItems={"center"} safeArea>
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <FlatList
      data={data?.myAudioGuides}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      refreshing={loading}
      onRefresh={() => refetch()}
      ListEmptyComponent={() => (
        <EmptyState
          icon="database-off-outline"
          title={useTranslate("No tienes ninguna audioguía comprada")+"..."}
          subtitle={useTranslate("Aqui aparecerán las audioguías una vez compradas")+"."}
        />
      )}
      p="4"
    />
  );
}

const Fonts = {
    textSize: 16,
    h1: 35,
    h2: 30,
    h3: 25,
    h4: 20,
    htmlCode: 16,
    seeComments: 16,
  };
  
  export default Fonts;
import {
  AudioGuide,
  useAudioGuideQuery,
} from "../types-graphql";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Image,
  ScrollView,
  Spinner,
  Text,
  View,
} from "native-base";
import { StyleSheet, useWindowDimensions } from "react-native";
import { useState } from "react";
import { store } from "../redux/store";

export default function ImageScreen({ route }: { route: any }) {
  const [imageUrl, setImageUrl] = useState(route.params.imageUrl);

  function getSrcImageStore(state: any) {
    return state.imageModalReducer.srcImageModal;
  }

  function handleChange() {
    let newImageUrl = getSrcImageStore(store.getState());

    if (imageUrl !== newImageUrl) {
      setImageUrl(newImageUrl);
    }
  }
  store.subscribe(handleChange);
  
  return (
    <View style={styles.container}>
      <Image
        source={{
          uri: imageUrl,
        }}
        w="100%"
        h="200"
        alt="image"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});

import { VERSION } from "@env";
import { Center, Text } from "native-base";
import React from "react";
import useTranslate from "../hooks/useTranslate";

function AppVersion() {
  return (
    <Center my="2">
        <Text color="muted.500" textAlign="center">
            {useTranslate("Versión")}: {VERSION}
        </Text>
    </Center>
  );
}

export default AppVersion;

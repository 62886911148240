import {
  Avatar,
  Box,
  Button,
  Heading,
  Icon,
  Input,
  Spinner,
  Text,
  View,
  VStack,
  Select,
  CheckIcon,
  ScrollView,
} from "native-base";
import { useDispatch } from "react-redux";

import { RootTabScreenProps } from "../../types";
import {
  useDeleteAccountMutation,
  useLanguagesQuery,
  useMeQuery,
  useUpdateProfileMutation,
} from "../../types-graphql.d";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import React, { useContext, useEffect, useState } from "react";
import { SafeAreaView } from "react-native";
import useErrorHandler from "../../hooks/useErrorHandler";
import { updatePreferredLangUser } from "../../redux/actions";
import useTranslate from "../../hooks/useTranslate";
import { LocalizationContext } from "../../i18n/LocalizationProvider";
import DeleteAccountModal from "../../components/DeleteAccountModal";

export default function EditProfileScreen({
  navigation,
}: RootTabScreenProps<"Profile">) {
  const { data, loading } = useMeQuery({
    fetchPolicy: "network-only",
  });
  const dispatch = useDispatch();
  const { data: dataLanguages, loading: loadingLanguages } = useLanguagesQuery({
    fetchPolicy: "network-only",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [password, onChangePassword] = useState("");
  const [passwordConfirmation, onChangePasswordConfirmation] = useState("");
  const [email, onChangeEmail] = useState("");
  const [phone, onChangePhone] = useState("");
  const [lastName, onChangeLastName] = useState("");
  const [name, onChangeName] = useState("");
  const [language, onChangeLanguage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateProfileMutation] = useUpdateProfileMutation();
  const { handle: handleError } = useErrorHandler();
  const i18n = useContext<LocalizationContext>(LocalizationContext);

  const [openModal, setOpenmodal] = React.useState(false);

  const updateProfile = async () => {
    try {
      setIsLoading(true);
      const data = await updateProfileMutation({
        variables: {
          input: {
            phone,
            name,
            lastName,
            email,
            password,
            password_confirmation: passwordConfirmation,
            language,
          },
        },
      });
      console.log(data.data?.updateProfile);
      dispatch(
        await updatePreferredLangUser(data.data?.updateProfile.preferredLang)
      );
      onChangeEmail(data.data?.updateProfile.email || "");
      onChangePhone(data.data?.updateProfile.phone || "");
      onChangeName(data.data?.updateProfile.name || "");
      onChangeLastName(data.data?.updateProfile.lastName || "");
      onChangePassword("");
      onChangePasswordConfirmation("");
      navigation.reset({
        index: 0,
        routes: [{ name: "Root", params: { tab: "Profile" } }],
      });
    } catch (e) {
      console.log(e);
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      onChangeEmail(data?.me?.email || "");
      onChangePhone(data?.me?.phone || "");
      onChangeName(data?.me?.name || "");
      onChangeLastName(data?.me?.lastName || "");
      onChangeLanguage(data?.me?.preferredLang?.id || "");
    }
  }, [data]);

  if (loading) {
    return (
      <Box flex={1} justifyContent="center" alignItems={"center"} safeArea>
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingHorizontal: 4 * 4,
        marginTop: 10 * 4,
      }}
    >
      <DeleteAccountModal openModal={openModal} />
      <ScrollView>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View maxWidth={"md"} width={"100%"} mb="10">
            <Input
              value={name}
              onChangeText={onChangeName}
              placeholder={useTranslate("Nombre")}
              textContentType="name"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="person" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={lastName}
              onChangeText={onChangeLastName}
              placeholder={useTranslate("Apellidos")}
              textContentType="givenName"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="person" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={email}
              onChangeText={onChangeEmail}
              placeholder={useTranslate("Correo electrónico")}
              textContentType="emailAddress"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="mail" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={phone}
              onChangeText={onChangePhone}
              placeholder={useTranslate("Teléfono")}
              textContentType="telephoneNumber"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="phone" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={password}
              onChangeText={onChangePassword}
              placeholder={useTranslate("Contraseña")}
              secureTextEntry={!showPassword}
              textContentType={showPassword ? "none" : "password"}
              type={showPassword ? "text" : "password"}
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="lock" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
              InputRightElement={
                <Icon
                  as={
                    <MaterialIcons
                      name={showPassword ? "visibility" : "visibility-off"}
                    />
                  }
                  size={5}
                  mr="2"
                  color="muted.200"
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
            />
            <Input
              value={passwordConfirmation}
              onChangeText={onChangePasswordConfirmation}
              placeholder={useTranslate("Confirmación contraseña")}
              secureTextEntry={!showPassword}
              textContentType={showPassword ? "none" : "password"}
              type={showPassword ? "text" : "password"}
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="lock" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
              InputRightElement={
                <Icon
                  as={
                    <MaterialIcons
                      name={showPassword ? "visibility" : "visibility-off"}
                    />
                  }
                  size={5}
                  mr="2"
                  color="muted.200"
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
            />
            <Select
              selectedValue={language}
              minWidth="200"
              accessibilityLabel={useTranslate("Cambiar idioma")}
              placeholder={useTranslate("Cambiar idioma")}
              _selectedItem={{
                bg: "primary.500",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => onChangeLanguage(itemValue)}
            >
              {dataLanguages?.languages.map((lang) => {
                return (
                  <Select.Item
                    label={lang.name}
                    value={lang.id}
                    key={lang.id}
                  />
                );
              })}
            </Select>
          </View>
          <View maxWidth={"md"} width={"100%"}>
            <Button
              onPress={() => updateProfile()}
              mb="2"
              isLoading={isLoading}
            >
              {useTranslate("Guardar")}
            </Button>
            <Button variant="subtle" onPress={() => navigation.goBack()}>
              {useTranslate("Cancelar")}
            </Button>
            <Button
            variant="subtle"
            bg="danger.600"
            mt="8"
            onPress={() => setOpenmodal(!openModal)}
          >
            {useTranslate("Eliminar cuenta")}
          </Button>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

import { AlertDialog, Button } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { useDeleteAccountMutation } from "../types-graphql.d";
import { logout } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { LocalizationContext } from "../i18n/LocalizationProvider";

function DeleteAccountModal({ openModal }: { openModal: boolean }) {
  const [isOpen, setIsOpen] = React.useState(openModal);
  const navigation = useNavigation();
  const onClose = () => setIsOpen(false);

  const cancelRef = React.useRef(null);
  const dispatch = useDispatch();
  const [deleteAccountMutation] = useDeleteAccountMutation();
  const i18n = useContext<LocalizationContext>(LocalizationContext);

  useEffect(() => {
    setIsOpen(openModal);
  }, [openModal]);

  const deleteAccount = async () => {
    const deletedAccount = await deleteAccountMutation();
    if (deletedAccount) {
      dispatch(logout());
      navigation.reset({
        index: 0,
        routes: [{ name: "Root" }],
      });
      onClose();
    }
  };

  return (
    <>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{i18n.t("Eliminar cuenta")}</AlertDialog.Header>
          <AlertDialog.Body>
            {i18n.t(
              "¿Seguro que quiere eliminar su cuenta? Ya no podrá acceder a ella, ni a sus compras"
            ) + "."}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={cancelRef}
              >
                {i18n.t("Cancelar")}
              </Button>
              <Button colorScheme="danger" onPress={deleteAccount}>
              {i18n.t("Ok")}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}

export default DeleteAccountModal;

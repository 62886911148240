import {
  Avatar,
  Box,
  Button,
  Heading,
  Icon,
  ScrollView,
  Spinner,
  Text,
  View,
  VStack,
} from "native-base";
import { useDispatch } from "react-redux";

import { RootStackParamList, RootTabScreenProps } from "../../types";
import { useDynamicPageQuery } from "../../types-graphql.d";
import useTranslate from "../../hooks/useTranslate";
import { DynamicPages } from "../../types/DynamicPages";
import { RenderHTMLSource } from "react-native-render-html";
import { useWindowDimensions } from "react-native";

export default function LegalWarningScreen({
  navigation,
}: RootTabScreenProps<"Profile">) {
  const { data, loading } = useDynamicPageQuery({
    variables: { type: DynamicPages.LEGAL_WARNING },
    fetchPolicy: "network-only",
  });
  const { width: AppWidth } = useWindowDimensions();

  if (loading) {
    return (
      <Box flex={1} justifyContent="center" alignItems={"center"} safeArea>
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <View flex={1} px="4" mt={10}>
      <View>
        <ScrollView>
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View maxWidth={"750px"} width={"100%"} mb="10">
              <RenderHTMLSource
                contentWidth={750}
                source={{ html: data?.dynamicPage.content as string }}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

import {
  Text,
  Modal,
  Button,
  Icon,
  HStack,
  Image,
  Pressable,
} from "native-base";
import React, { useEffect, useState } from "react";
import { View } from "./Themed";
import useTranslate from "../hooks/useTranslate";
import { ANDROID_PLAYSTORE_URL, IOS_APPSTORE_URL } from "@env";

function DownloadAppModal({ downloadAppModal }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    downloadAppModal.current = init;
  }, []);

  async function init() {
    setShowModal(true);
  }

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
      <Modal.Content maxWidth="700px">
        <Modal.CloseButton />
        <Modal.Header>{useTranslate("Descargar la app")}</Modal.Header>
        <Modal.Body>
          <View>
            <Text color="primary.500" fontSize="lg" fontWeight="bold" py="2">
              {useTranslate(
                "Para una mejor experiencia, descarga la app donde podrás encontrar todo lo que necesitas para tu visita turística"
              )}.
            </Text>
            <HStack flex="1" w="100%" justifyContent="space-evenly">
              <Pressable
                onPress={() => {
                  window.open(ANDROID_PLAYSTORE_URL, "_blank");
                }}
              >
                <Image
                  h={"75px"}
                  w={"192px"}
                  resizeMode="contain"
                  mb="6"
                  source={require("../assets/images/google-play-badge.png")}
                />
              </Pressable>
              <Pressable
                onPress={() => {
                  window.open(IOS_APPSTORE_URL, "_blank");
                }}
              >
                <Image
                  h={"56px"}
                  w={"165px"}
                  resizeMode="contain"
                  mt="3"
                  source={require("../assets/images/apple-appstore-badge.svg")}
                />
              </Pressable>
            </HStack>
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

export default DownloadAppModal;

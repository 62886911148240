import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Center, Heading, Icon, Text } from 'native-base';
import React from 'react'

function EmptyState({ icon, title, subtitle }: { icon: string, title: string, subtitle: string }) {
    return (
      <Center>
        <Center bgColor={'muted.200'} borderRadius='full' p='4' mb='2'>
          <Icon
            as={<MaterialCommunityIcons name={icon} />}
            size={24}
            color={'muted.500'}
          />
        </Center>
        <Heading fontSize={"md"}>{ title }</Heading>
        <Text textAlign={'center'}>{ subtitle }</Text>
      </Center>
    );
  }

export default EmptyState
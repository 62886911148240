import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Audio = {
  __typename?: 'Audio';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  images: Array<Image>;
  lang: Lang;
  pivot?: Maybe<AudioImagePivot>;
  place: Place;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type AudioGuide = {
  __typename?: 'AudioGuide';
  averageRating: Scalars['Float'];
  categories: Array<Category>;
  cities: Array<Maybe<City>>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  images: Array<Image>;
  orders?: Maybe<Array<Order>>;
  pivot?: Maybe<AudioGuidePlacePivot>;
  places: Array<Place>;
  price: Scalars['Float'];
  reviews?: Maybe<Array<Review>>;
  route?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** A paginated list of AudioGuide items. */
export type AudioGuidePaginator = {
  __typename?: 'AudioGuidePaginator';
  /** A list of AudioGuide items. */
  data: Array<AudioGuide>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AudioGuidePlacePivot = {
  __typename?: 'AudioGuidePlacePivot';
  order?: Maybe<Scalars['Int']>;
};

export type AudioImagePivot = {
  __typename?: 'AudioImagePivot';
  endTime?: Maybe<Scalars['Int']>;
  initTime?: Maybe<Scalars['Int']>;
};

export type Category = {
  __typename?: 'Category';
  audioGuides: Array<AudioGuide>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** A paginated list of Category items. */
export type CategoryPaginator = {
  __typename?: 'CategoryPaginator';
  /** A list of Category items. */
  data: Array<Category>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type City = {
  __typename?: 'City';
  audioGuides: Array<AudioGuide>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** A paginated list of City items. */
export type CityPaginator = {
  __typename?: 'CityPaginator';
  /** A list of City items. */
  data: Array<City>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Customer = {
  __typename?: 'Customer';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dni?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  orders?: Maybe<Array<Order>>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferredLang: Lang;
  reviews?: Maybe<Array<Review>>;
  updatedAt: Scalars['DateTime'];
};

export type DynamicPage = {
  __typename?: 'DynamicPage';
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  audio: Audio;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  pivot?: Maybe<AudioImagePivot>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type InvoicingInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dni: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
};

export type Lang = {
  __typename?: 'Lang';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Message = {
  __typename?: 'Message';
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createPaymentIntent?: Maybe<Order>;
  deleteAccount: Scalars['Boolean'];
  forgotPassword: Message;
  login?: Maybe<Token>;
  logout?: Maybe<Scalars['Boolean']>;
  postReview: Review;
  register: Customer;
  resetPassword: Message;
  updateInvoicing: Customer;
  updateProfile: Customer;
};


export type MutationCreatePaymentIntentArgs = {
  orderInput: OrderInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
};


export type MutationPostReviewArgs = {
  input: ReviewInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationUpdateInvoicingArgs = {
  input: InvoicingInput;
};


export type MutationUpdateProfileArgs = {
  input: ProfileInput;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float'];
  audioGuide?: Maybe<AudioGuide>;
  clientSecret?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  id: Scalars['ID'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  webhookConfirm?: Maybe<Scalars['DateTime']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type OrderInput = {
  audioGuideId?: InputMaybe<Scalars['ID']>;
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Place = {
  __typename?: 'Place';
  audio?: Maybe<Audio>;
  audioGuides: Array<AudioGuide>;
  audios?: Maybe<Array<Audio>>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  images: Array<Image>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  pivot?: Maybe<AudioGuidePlacePivot>;
  updatedAt: Scalars['DateTime'];
  video?: Maybe<Video>;
  videos?: Maybe<Array<Video>>;
};

export type ProfileInput = {
  email: Scalars['String'];
  language: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  phone: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  audio?: Maybe<Audio>;
  audioGuide?: Maybe<AudioGuide>;
  audioGuides?: Maybe<AudioGuidePaginator>;
  categories?: Maybe<CategoryPaginator>;
  category?: Maybe<Category>;
  checkConnection: Scalars['Boolean'];
  cities?: Maybe<CityPaginator>;
  dynamicPage: DynamicPage;
  languages: Array<Lang>;
  me?: Maybe<Customer>;
  myAudioGuides: Array<AudioGuide>;
  myPendingReviews: Array<AudioGuide>;
  myReviews: Array<Review>;
  place?: Maybe<Place>;
  search: Array<AudioGuide>;
  video?: Maybe<Video>;
};


export type QueryAudioArgs = {
  id: Scalars['ID'];
};


export type QueryAudioGuideArgs = {
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryAudioGuidesArgs = {
  first: Scalars['Int'];
  lang?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCategoriesArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryCitiesArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryDynamicPageArgs = {
  type: Scalars['String'];
};


export type QueryPlaceArgs = {
  id: Scalars['ID'];
};


export type QuerySearchArgs = {
  input?: InputMaybe<SearchInput>;
};


export type QueryVideoArgs = {
  id: Scalars['ID'];
};

export type RegisterInput = {
  email: Scalars['String'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  audioGuide: AudioGuide;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  id: Scalars['ID'];
  review?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type ReviewInput = {
  audioGuideId: Scalars['ID'];
  content?: InputMaybe<Scalars['String']>;
  value: Scalars['Int'];
};

export type SearchInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  cityId?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
  search: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Token = {
  __typename?: 'Token';
  preferredLang: Lang;
  token: Scalars['String'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type Video = {
  __typename?: 'Video';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  place: Place;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type AudioGuideQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
}>;


export type AudioGuideQuery = { __typename?: 'Query', audioGuide?: { __typename?: 'AudioGuide', id: string, title?: string | null, description?: string | null, price: number, duration: number, imageUrl?: string | null, route?: string | null, averageRating: number, places: Array<{ __typename?: 'Place', id: string, name?: string | null, latitude: number, longitude: number }>, images: Array<{ __typename?: 'Image', id: string, url?: string | null }>, reviews?: Array<{ __typename?: 'Review', id: string, value: number, review?: string | null }> | null } | null };

export type AudioGuideReviewsQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
}>;


export type AudioGuideReviewsQuery = { __typename?: 'Query', audioGuide?: { __typename?: 'AudioGuide', id: string, title?: string | null, averageRating: number, reviews?: Array<{ __typename?: 'Review', id: string, value: number, review?: string | null, customer: { __typename?: 'Customer', name: string, lastName: string } }> | null } | null };

export type AudioGuidesQueryVariables = Exact<{
  page: Scalars['Int'];
  lang?: InputMaybe<Scalars['String']>;
}>;


export type AudioGuidesQuery = { __typename?: 'Query', audioGuides?: { __typename?: 'AudioGuidePaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number, perPage: number, currentPage: number }, data: Array<{ __typename?: 'AudioGuide', id: string, title?: string | null, description?: string | null, price: number, duration: number, imageUrl?: string | null }> } | null };

export type CategoriesQueryVariables = Exact<{
  page: Scalars['Int'];
}>;


export type CategoriesQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoryPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number, perPage: number, currentPage: number }, data: Array<{ __typename?: 'Category', id: string, name?: string | null, imageUrl?: string | null }> } | null };

export type CategoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CategoryQuery = { __typename?: 'Query', category?: { __typename?: 'Category', id: string, audioGuides: Array<{ __typename?: 'AudioGuide', id: string, title?: string | null, price: number, duration: number, imageUrl?: string | null, images: Array<{ __typename?: 'Image', id: string, url?: string | null }> }> } | null };

export type CitiesQueryVariables = Exact<{
  page: Scalars['Int'];
}>;


export type CitiesQuery = { __typename?: 'Query', cities?: { __typename?: 'CityPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number, perPage: number, currentPage: number }, data: Array<{ __typename?: 'City', id: string, name?: string | null }> } | null };

export type CreatePaymentIntentMutationVariables = Exact<{
  orderInput: OrderInput;
}>;


export type CreatePaymentIntentMutation = { __typename?: 'Mutation', createPaymentIntent?: { __typename?: 'Order', id: string, clientSecret?: string | null, amount: number, customer?: { __typename?: 'Customer', id: string } | null, audioGuide?: { __typename?: 'AudioGuide', id: string } | null } | null };

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount: boolean };

export type DynamicPageQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type DynamicPageQuery = { __typename?: 'Query', dynamicPage: { __typename?: 'DynamicPage', content: string } };

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'Message', message: string } };

export type LanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagesQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'Lang', id: string, name: string, code: string }> };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'Token', token: string, preferredLang: { __typename?: 'Lang', id: string, name: string, code: string } } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: boolean | null };

export type CheckConnectionQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckConnectionQuery = { __typename?: 'Query', checkConnection: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'Customer', id: string, name: string, lastName: string, email: string, address?: string | null, city?: string | null, country?: string | null, postalCode?: string | null, phone?: string | null, dni?: string | null, preferredLang: { __typename?: 'Lang', id: string, name: string, code: string } } | null };

export type MyAudioGuideDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MyAudioGuideDetailQuery = { __typename?: 'Query', audioGuide?: { __typename?: 'AudioGuide', id: string, title?: string | null, description?: string | null, price: number, duration: number, imageUrl?: string | null, route?: string | null, places: Array<{ __typename?: 'Place', id: string, name?: string | null, description?: string | null, imageUrl?: string | null, latitude: number, longitude: number, pivot?: { __typename?: 'AudioGuidePlacePivot', order?: number | null } | null, audio?: { __typename?: 'Audio', id: string } | null, video?: { __typename?: 'Video', id: string } | null }>, images: Array<{ __typename?: 'Image', id: string, url?: string | null }> } | null };

export type MyAudioGuidesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAudioGuidesQuery = { __typename?: 'Query', myAudioGuides: Array<{ __typename?: 'AudioGuide', id: string, title?: string | null, description?: string | null, price: number, duration: number, imageUrl?: string | null }> };

export type MyPendingReviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPendingReviewsQuery = { __typename?: 'Query', myPendingReviews: Array<{ __typename?: 'AudioGuide', id: string, title?: string | null, description?: string | null, price: number, averageRating: number }> };

export type MyReviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyReviewsQuery = { __typename?: 'Query', myReviews: Array<{ __typename?: 'Review', id: string, review?: string | null, value: number, createdAt: any, updatedAt: any, audioGuide: { __typename?: 'AudioGuide', id: string, title?: string | null, averageRating: number } }> };

export type PostReviewMutationVariables = Exact<{
  reviewInput: ReviewInput;
}>;


export type PostReviewMutation = { __typename?: 'Mutation', postReview: { __typename?: 'Review', id: string, review?: string | null, value: number } };

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'Customer', id: string, email: string } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'Message', message: string } };

export type SearchQueryVariables = Exact<{
  input: SearchInput;
}>;


export type SearchQuery = { __typename?: 'Query', search: Array<{ __typename?: 'AudioGuide', id: string, title?: string | null, description?: string | null, price: number, duration: number, imageUrl?: string | null, images: Array<{ __typename?: 'Image', id: string, url?: string | null }> }> };

export type UpdateInvoicingMutationVariables = Exact<{
  input: InvoicingInput;
}>;


export type UpdateInvoicingMutation = { __typename?: 'Mutation', updateInvoicing: { __typename?: 'Customer', id: string, name: string, lastName: string, address?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, dni?: string | null } };

export type UpdateProfileMutationVariables = Exact<{
  input: ProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'Customer', id: string, name: string, phone?: string | null, lastName: string, email: string, preferredLang: { __typename?: 'Lang', id: string, name: string, code: string } } };


export const AudioGuideDocument = gql`
    query audioGuide($id: ID!, $lang: String) {
  audioGuide(id: $id, lang: $lang) {
    id
    title
    description
    price
    duration
    imageUrl
    places {
      id
      name
      latitude
      longitude
    }
    route
    images {
      id
      url
    }
    averageRating
    reviews {
      id
      value
      review
    }
  }
}
    `;

/**
 * __useAudioGuideQuery__
 *
 * To run a query within a React component, call `useAudioGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudioGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudioGuideQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useAudioGuideQuery(baseOptions: Apollo.QueryHookOptions<AudioGuideQuery, AudioGuideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AudioGuideQuery, AudioGuideQueryVariables>(AudioGuideDocument, options);
      }
export function useAudioGuideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AudioGuideQuery, AudioGuideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AudioGuideQuery, AudioGuideQueryVariables>(AudioGuideDocument, options);
        }
export type AudioGuideQueryHookResult = ReturnType<typeof useAudioGuideQuery>;
export type AudioGuideLazyQueryHookResult = ReturnType<typeof useAudioGuideLazyQuery>;
export type AudioGuideQueryResult = Apollo.QueryResult<AudioGuideQuery, AudioGuideQueryVariables>;
export const AudioGuideReviewsDocument = gql`
    query audioGuideReviews($id: ID!, $lang: String) {
  audioGuide(id: $id, lang: $lang) {
    id
    title
    averageRating
    reviews {
      id
      value
      review
      customer {
        name
        lastName
      }
    }
  }
}
    `;

/**
 * __useAudioGuideReviewsQuery__
 *
 * To run a query within a React component, call `useAudioGuideReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudioGuideReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudioGuideReviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useAudioGuideReviewsQuery(baseOptions: Apollo.QueryHookOptions<AudioGuideReviewsQuery, AudioGuideReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AudioGuideReviewsQuery, AudioGuideReviewsQueryVariables>(AudioGuideReviewsDocument, options);
      }
export function useAudioGuideReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AudioGuideReviewsQuery, AudioGuideReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AudioGuideReviewsQuery, AudioGuideReviewsQueryVariables>(AudioGuideReviewsDocument, options);
        }
export type AudioGuideReviewsQueryHookResult = ReturnType<typeof useAudioGuideReviewsQuery>;
export type AudioGuideReviewsLazyQueryHookResult = ReturnType<typeof useAudioGuideReviewsLazyQuery>;
export type AudioGuideReviewsQueryResult = Apollo.QueryResult<AudioGuideReviewsQuery, AudioGuideReviewsQueryVariables>;
export const AudioGuidesDocument = gql`
    query audioGuides($page: Int!, $lang: String) {
  audioGuides(first: 10, page: $page, lang: $lang) {
    paginatorInfo {
      total
      perPage
      currentPage
    }
    data {
      id
      title
      description
      price
      duration
      imageUrl
    }
  }
}
    `;

/**
 * __useAudioGuidesQuery__
 *
 * To run a query within a React component, call `useAudioGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudioGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudioGuidesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useAudioGuidesQuery(baseOptions: Apollo.QueryHookOptions<AudioGuidesQuery, AudioGuidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AudioGuidesQuery, AudioGuidesQueryVariables>(AudioGuidesDocument, options);
      }
export function useAudioGuidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AudioGuidesQuery, AudioGuidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AudioGuidesQuery, AudioGuidesQueryVariables>(AudioGuidesDocument, options);
        }
export type AudioGuidesQueryHookResult = ReturnType<typeof useAudioGuidesQuery>;
export type AudioGuidesLazyQueryHookResult = ReturnType<typeof useAudioGuidesLazyQuery>;
export type AudioGuidesQueryResult = Apollo.QueryResult<AudioGuidesQuery, AudioGuidesQueryVariables>;
export const CategoriesDocument = gql`
    query categories($page: Int!) {
  categories(first: 100, page: $page) {
    paginatorInfo {
      total
      perPage
      currentPage
    }
    data {
      id
      name
      imageUrl
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryDocument = gql`
    query category($id: ID!) {
  category(id: $id) {
    id
    audioGuides {
      id
      title
      price
      duration
      imageUrl
      images {
        id
        url
      }
    }
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const CitiesDocument = gql`
    query cities($page: Int!) {
  cities(first: 100, page: $page) {
    paginatorInfo {
      total
      perPage
      currentPage
    }
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCitiesQuery(baseOptions: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation CreatePaymentIntent($orderInput: OrderInput!) {
  createPaymentIntent(orderInput: $orderInput) {
    id
    customer {
      id
    }
    audioGuide {
      id
    }
    clientSecret
    amount
  }
}
    `;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, options);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount {
  deleteAccount
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const DynamicPageDocument = gql`
    query DynamicPage($type: String!) {
  dynamicPage(type: $type) {
    content
  }
}
    `;

/**
 * __useDynamicPageQuery__
 *
 * To run a query within a React component, call `useDynamicPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicPageQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDynamicPageQuery(baseOptions: Apollo.QueryHookOptions<DynamicPageQuery, DynamicPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DynamicPageQuery, DynamicPageQueryVariables>(DynamicPageDocument, options);
      }
export function useDynamicPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DynamicPageQuery, DynamicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DynamicPageQuery, DynamicPageQueryVariables>(DynamicPageDocument, options);
        }
export type DynamicPageQueryHookResult = ReturnType<typeof useDynamicPageQuery>;
export type DynamicPageLazyQueryHookResult = ReturnType<typeof useDynamicPageLazyQuery>;
export type DynamicPageQueryResult = Apollo.QueryResult<DynamicPageQuery, DynamicPageQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    message
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LanguagesDocument = gql`
    query Languages {
  languages {
    id
    name
    code
  }
}
    `;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
        }
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!, $source: String) {
  login(email: $email, password: $password, source: $source) {
    token
    preferredLang {
      id
      name
      code
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CheckConnectionDocument = gql`
    query checkConnection {
  checkConnection
}
    `;

/**
 * __useCheckConnectionQuery__
 *
 * To run a query within a React component, call `useCheckConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckConnectionQuery(baseOptions?: Apollo.QueryHookOptions<CheckConnectionQuery, CheckConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckConnectionQuery, CheckConnectionQueryVariables>(CheckConnectionDocument, options);
      }
export function useCheckConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckConnectionQuery, CheckConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckConnectionQuery, CheckConnectionQueryVariables>(CheckConnectionDocument, options);
        }
export type CheckConnectionQueryHookResult = ReturnType<typeof useCheckConnectionQuery>;
export type CheckConnectionLazyQueryHookResult = ReturnType<typeof useCheckConnectionLazyQuery>;
export type CheckConnectionQueryResult = Apollo.QueryResult<CheckConnectionQuery, CheckConnectionQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    lastName
    email
    address
    city
    country
    postalCode
    phone
    dni
    preferredLang {
      id
      name
      code
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyAudioGuideDetailDocument = gql`
    query MyAudioGuideDetail($id: ID!) {
  audioGuide(id: $id) {
    id
    title
    description
    price
    duration
    imageUrl
    places {
      id
      name
      description
      imageUrl
      latitude
      longitude
      pivot {
        order
      }
      audio {
        id
      }
      video {
        id
      }
    }
    route
    images {
      id
      url
    }
  }
}
    `;

/**
 * __useMyAudioGuideDetailQuery__
 *
 * To run a query within a React component, call `useMyAudioGuideDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAudioGuideDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAudioGuideDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyAudioGuideDetailQuery(baseOptions: Apollo.QueryHookOptions<MyAudioGuideDetailQuery, MyAudioGuideDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAudioGuideDetailQuery, MyAudioGuideDetailQueryVariables>(MyAudioGuideDetailDocument, options);
      }
export function useMyAudioGuideDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAudioGuideDetailQuery, MyAudioGuideDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAudioGuideDetailQuery, MyAudioGuideDetailQueryVariables>(MyAudioGuideDetailDocument, options);
        }
export type MyAudioGuideDetailQueryHookResult = ReturnType<typeof useMyAudioGuideDetailQuery>;
export type MyAudioGuideDetailLazyQueryHookResult = ReturnType<typeof useMyAudioGuideDetailLazyQuery>;
export type MyAudioGuideDetailQueryResult = Apollo.QueryResult<MyAudioGuideDetailQuery, MyAudioGuideDetailQueryVariables>;
export const MyAudioGuidesDocument = gql`
    query myAudioGuides {
  myAudioGuides {
    id
    title
    description
    price
    duration
    imageUrl
  }
}
    `;

/**
 * __useMyAudioGuidesQuery__
 *
 * To run a query within a React component, call `useMyAudioGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAudioGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAudioGuidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAudioGuidesQuery(baseOptions?: Apollo.QueryHookOptions<MyAudioGuidesQuery, MyAudioGuidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAudioGuidesQuery, MyAudioGuidesQueryVariables>(MyAudioGuidesDocument, options);
      }
export function useMyAudioGuidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAudioGuidesQuery, MyAudioGuidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAudioGuidesQuery, MyAudioGuidesQueryVariables>(MyAudioGuidesDocument, options);
        }
export type MyAudioGuidesQueryHookResult = ReturnType<typeof useMyAudioGuidesQuery>;
export type MyAudioGuidesLazyQueryHookResult = ReturnType<typeof useMyAudioGuidesLazyQuery>;
export type MyAudioGuidesQueryResult = Apollo.QueryResult<MyAudioGuidesQuery, MyAudioGuidesQueryVariables>;
export const MyPendingReviewsDocument = gql`
    query MyPendingReviews {
  myPendingReviews {
    id
    title
    description
    price
    averageRating
  }
}
    `;

/**
 * __useMyPendingReviewsQuery__
 *
 * To run a query within a React component, call `useMyPendingReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPendingReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPendingReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPendingReviewsQuery(baseOptions?: Apollo.QueryHookOptions<MyPendingReviewsQuery, MyPendingReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyPendingReviewsQuery, MyPendingReviewsQueryVariables>(MyPendingReviewsDocument, options);
      }
export function useMyPendingReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyPendingReviewsQuery, MyPendingReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyPendingReviewsQuery, MyPendingReviewsQueryVariables>(MyPendingReviewsDocument, options);
        }
export type MyPendingReviewsQueryHookResult = ReturnType<typeof useMyPendingReviewsQuery>;
export type MyPendingReviewsLazyQueryHookResult = ReturnType<typeof useMyPendingReviewsLazyQuery>;
export type MyPendingReviewsQueryResult = Apollo.QueryResult<MyPendingReviewsQuery, MyPendingReviewsQueryVariables>;
export const MyReviewsDocument = gql`
    query myReviews {
  myReviews {
    id
    audioGuide {
      id
      title
      averageRating
    }
    review
    value
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMyReviewsQuery__
 *
 * To run a query within a React component, call `useMyReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyReviewsQuery(baseOptions?: Apollo.QueryHookOptions<MyReviewsQuery, MyReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyReviewsQuery, MyReviewsQueryVariables>(MyReviewsDocument, options);
      }
export function useMyReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyReviewsQuery, MyReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyReviewsQuery, MyReviewsQueryVariables>(MyReviewsDocument, options);
        }
export type MyReviewsQueryHookResult = ReturnType<typeof useMyReviewsQuery>;
export type MyReviewsLazyQueryHookResult = ReturnType<typeof useMyReviewsLazyQuery>;
export type MyReviewsQueryResult = Apollo.QueryResult<MyReviewsQuery, MyReviewsQueryVariables>;
export const PostReviewDocument = gql`
    mutation PostReview($reviewInput: ReviewInput!) {
  postReview(input: $reviewInput) {
    id
    review
    value
  }
}
    `;
export type PostReviewMutationFn = Apollo.MutationFunction<PostReviewMutation, PostReviewMutationVariables>;

/**
 * __usePostReviewMutation__
 *
 * To run a mutation, you first call `usePostReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postReviewMutation, { data, loading, error }] = usePostReviewMutation({
 *   variables: {
 *      reviewInput: // value for 'reviewInput'
 *   },
 * });
 */
export function usePostReviewMutation(baseOptions?: Apollo.MutationHookOptions<PostReviewMutation, PostReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostReviewMutation, PostReviewMutationVariables>(PostReviewDocument, options);
      }
export type PostReviewMutationHookResult = ReturnType<typeof usePostReviewMutation>;
export type PostReviewMutationResult = Apollo.MutationResult<PostReviewMutation>;
export type PostReviewMutationOptions = Apollo.BaseMutationOptions<PostReviewMutation, PostReviewMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($input: RegisterInput!) {
  register(input: $input) {
    id
    email
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    message
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SearchDocument = gql`
    query search($input: SearchInput!) {
  search(input: $input) {
    id
    title
    description
    price
    duration
    imageUrl
    images {
      id
      url
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const UpdateInvoicingDocument = gql`
    mutation UpdateInvoicing($input: InvoicingInput!) {
  updateInvoicing(input: $input) {
    id
    name
    lastName
    address
    postalCode
    city
    country
    dni
  }
}
    `;
export type UpdateInvoicingMutationFn = Apollo.MutationFunction<UpdateInvoicingMutation, UpdateInvoicingMutationVariables>;

/**
 * __useUpdateInvoicingMutation__
 *
 * To run a mutation, you first call `useUpdateInvoicingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoicingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoicingMutation, { data, loading, error }] = useUpdateInvoicingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoicingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoicingMutation, UpdateInvoicingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoicingMutation, UpdateInvoicingMutationVariables>(UpdateInvoicingDocument, options);
      }
export type UpdateInvoicingMutationHookResult = ReturnType<typeof useUpdateInvoicingMutation>;
export type UpdateInvoicingMutationResult = Apollo.MutationResult<UpdateInvoicingMutation>;
export type UpdateInvoicingMutationOptions = Apollo.BaseMutationOptions<UpdateInvoicingMutation, UpdateInvoicingMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: ProfileInput!) {
  updateProfile(input: $input) {
    id
    name
    phone
    lastName
    email
    preferredLang {
      id
      name
      code
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;

declare module '*/audioGuideDetail.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const audioGuide: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/audioGuideReviews.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const audioGuideReviews: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/audioGuides.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const audioGuides: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/categories.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const categories: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/category.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const category: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/cities.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const cities: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/createPaymentIntent.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const CreatePaymentIntent: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/deleteAccount.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const DeleteAccount: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/dynamicPage.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const DynamicPage: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/forgot-password.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const ForgotPassword: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/languages.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const Languages: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/login.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const Login: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/logout.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const Logout: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/maintenance.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const checkConnection: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/me.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const Me: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/my-audioguide-detail.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const MyAudioGuideDetail: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/myAudioGuides.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const myAudioGuides: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/myPendingReviews.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const MyPendingReviews: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/myReviews.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const myReviews: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/postReview.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const PostReview: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/register.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const Register: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/reset-password.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const ResetPassword: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/search.query.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const search: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/updateInvoicing.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const UpdateInvoicing: DocumentNode;

  export default defaultDocument;
}
    

declare module '*/updateProfile.mutation.graphql' {
  import { DocumentNode } from 'graphql';
  const defaultDocument: DocumentNode;
  export const UpdateProfile: DocumentNode;

  export default defaultDocument;
}
    
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Icon, Box, View } from "native-base";
import React from "react";

function ReviewStars({ rate }: { rate: number }) {
  function iconeStar(index: number): any {
    if (rate > index - 1 && rate < index) {
      return "star-half-full";
    }
    return "star";
  }

  function colorStar(index: number): string {
    if (index <= Math.ceil(rate)) {
      return "yellow.500";
    }
    return "black";
  }

  var stars = [];
  for (var i = 1; i <= 5; i++) {
    stars.push(
      <Icon
        as={<MaterialCommunityIcons name={iconeStar(i)} />}
        size={5}
        ml="2"
        color={colorStar(i)}
        key={i}
      />
    );
  }

  return <View flexDir="row">{stars}</View>;
}

export default ReviewStars;

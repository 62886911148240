import React, { useState, useEffect, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Center, View } from "native-base";
import useTranslate from "../hooks/useTranslate";
import { Alert } from "react-native";
import { LocalizationContext } from "../i18n/LocalizationProvider";
import useErrorHandler from "../hooks/useErrorHandler";
import { useNavigation } from "@react-navigation/native";

export default function CheckoutForm({
  clientSecret,
}: {
  clientSecret: string;
}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const { handle: handleError } = useErrorHandler();
  const navigation = useNavigation();
  const i18n = useContext<LocalizationContext>(LocalizationContext);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      Alert.alert(i18n.t("Ha ocurrido un error durante el pago"));
      handleError(payload.error.message);
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      console.log("Payment confirmation error", error);
    } else if (payload.paymentIntent.status === "succeeded") {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      navigation.reset({
        index: 0,
        routes: [{ name: "Root", params: { tab: "Guides" } }],
      });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <View py={6}>
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
      </View>
      <Center>
        <Button
          w="sm"
          bg="primary.500"
          onPress={handleSubmit}
          isDisabled={processing || disabled || succeeded}
          isLoading={processing}
        >
          {useTranslate("Pagar")}
        </Button>
      </Center>
    </form>
  );
}

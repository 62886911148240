import {
    AudioGuide,
    Review,
    useAudioGuideReviewsQuery,
  } from "../types-graphql.d";
  import { ScrollView, View, Text, Avatar, FlatList, Center } from "native-base";
  import { StyleSheet } from "react-native";
  import { useContext, useEffect, useState } from "react";
  import { LocalizationContext } from "../i18n/LocalizationProvider";
  import EmptyState from "../components/EmptyState";
  import ReviewStars from "../components/ReviewStars";
  import useTranslate from "../hooks/useTranslate";
import { WITH_CONTENT } from "../constants/Layout";
  
  export default function ReviewsScreen({ route }: { route: any }) {
    const audioGuideId = route.params.guideId;
    const i18n = useContext<LocalizationContext>(LocalizationContext);
    const { data, loading, error } = useAudioGuideReviewsQuery({
      fetchPolicy: "network-only",
      variables: {
        id: audioGuideId,
        lang: i18n.getLocale(),
      },
    });
  
    const [reviews, setReviews] = useState([] as Review[]);
  
    useEffect(() => {
      setReviews(data?.audioGuide?.reviews as Review[]);
    }, [data]);
  
    const ReviewComponent = ({ item: review }: { item: Review }) => {
      return (
        <View pb="6">
          <Center>
            <Avatar size="md" bg="primary.500" mb={2}>
              {review?.customer?.name?.charAt(0).toUpperCase() +
                "" +
                review?.customer?.lastName?.charAt(0).toUpperCase()}
            </Avatar>
            <Text pb="2" fontWeight={"bold"}>
              {review?.customer.name} {review?.customer.lastName}
            </Text>
            <ReviewStars rate={review?.value} />
          </Center>
          <Text pt="2" textAlign={"justify"}>
            {review?.review}
          </Text>
        </View>
      );
    };
  
    return (
      <Center>
      <View maxWidth={WITH_CONTENT} width={"100%"}>
        <FlatList
          data={reviews}
          renderItem={ReviewComponent}
          keyExtractor={(item) => item.id}
          refreshing={loading}
          ListEmptyComponent={() => (
            <EmptyState
              icon="star-half-full"
              title={useTranslate("Nadie han valorado aún esta audioguía")+"..."}
              subtitle={useTranslate("Tu puedes ser el primero en valorarla")+"."}
            />
          )}
          p="4"
        />
      </View>
      </Center>
    );
  }
  
  const styles = StyleSheet.create({
    container: {
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
  });
  
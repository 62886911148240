import {ServiceBundleType} from '../errors/Wall_e';
import {Wall_eError} from '@markdomkan/wall_e';

export class Unauthenticated extends Wall_eError { 
    message = 'Para acceder aqui, es necesario estar logeado';
  action = async (serviceBundle: ServiceBundleType) => {
    serviceBundle.showErrorMessageInAlert(this.message);
    serviceBundle.navigate('Login');
  };
}

export class MaintenanceMode extends Wall_eError {
  action = async (serviceBundle: ServiceBundleType) =>
    serviceBundle.navigate('Maintenance');
}

export class VersionError extends Wall_eError {
  action = async (serviceBundle: ServiceBundleType) =>
    serviceBundle.navigate('Version');
}

export class NetworkRequestFailed extends Wall_eError {
  message =
    'Error de conexión. Parece que no hay conexión a internet o a nuestros servidores. \n\nPor favor, inténtelo de nuevo más tarde, y si el problema persiste, póngase en contacto con nosotros.';
  action = async (serviceBundle: ServiceBundleType) =>
    serviceBundle.showErrorMessageInAlert(this.message);
}

export class ValidationField extends Wall_eError {
  message = 'Hi ha algun problema amb els camps del formulari.';
  action = async (serviceBundle: ServiceBundleType) =>
    serviceBundle.showErrorMessageInAlert(this.message);
}

export function translateError(errorMessage: string): string {
  console.log("'" + errorMessage + "'");

  switch (errorMessage) {
    case 'The given data was invalid.':
      return 'Los datos introducidos no son válidos';
    case 'Validation failed for the field':
      return 'Los datos introducidos no son válidos';
    case 'The provided credentials are incorrect.':
      return 'No existe ningún usuario con este correo y contraseña.';
    case "We can't find a user with that email address.":
      return 'No se ha encontrado ningún usuario con ese correo electrónico.';
    case 'The reset password input.password field is required.':
      return 'La contraseña es obligatoria.';
    case 'The reset password input.password confirmation field is required.':
      return 'La confirmación de la contraseña es obligatoria.';
    case 'The reset password input.token field is required.':
      return 'El código de recuperación de contraseña es obligatorio.';
    case 'The reset password input.password confirmation does not match.':
      return 'La confirmación de la contraseña no coincide.';
    case 'The reset password input.password must be at least 8 characters.':
      return 'La longitud de la contraseña tiene que ser de al menos 8 caracteres.';
    case 'This password reset token is invalid.':
      return 'El código de recuperación de contraseña no es válido.';
    case 'The password input.password field is required.':
      return 'La contraseña nueva es obligatoria.';
    case 'The password input.password confirmation field is required.':
      return 'La confirmación de la contraseña nueva es obligatoria.';
    case 'The password input.password must be at least 8 characters.':
      return 'La longitud de la contraseña tiene que ser de al menos 8 caracteres.';
    case 'The password input.password confirmation does not match.':
      return 'La confirmación de la contraseña no coincide.';
    case 'Incorrect old password.':
      return 'La contraseña antigua no es correcta.';
    case 'The worker.name field is required.':
      return 'El nombre es obligatorio.';
    case 'The worker.email field is required.':
      return 'El correo electrónico es obligatorio.';
    case 'The worker.email has already been taken.':
      return 'Este correo electrónico ya lo tiene registrado otro usuario.';
    case 'The worker.email must be a valid email address.':
      return 'El correo electrónico no es válido.';
    default:
      return errorMessage;
  }
}

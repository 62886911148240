import {
  AspectRatio,
  Box,
  Button,
  Center,
  FlatList,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  Icon,
  Input,
  View,
  IconButton,
} from "native-base";
import { StyleSheet } from "react-native";
import GuideCard from "../components/GuideCard";
import { RootTabScreenProps } from "../types";
import {
  AudioGuide,
  useAudioGuidesQuery,
  useSearchQuery,
} from "../types-graphql.d";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import useThemeColor from "../hooks/useThemeColor";
import SearchModal from "../components/SearchModal";
import useTranslate from "../hooks/useTranslate";
import { LocalizationContext } from "../i18n/LocalizationProvider";
import useErrorHandler from "../hooks/useErrorHandler";
import { WITH_CONTENT } from "../constants/Layout";
import EmptyState from "../components/EmptyState";

export default function HomeScreen({ navigation }: RootTabScreenProps<"Home">) {
  const [audioGuides, setAudioGuides] = useState([] as AudioGuide[]);
  const [search, onChangeSearch] = useState("");
  const [category, setCategory] = useState("0");
  const [city, setCity] = useState("0");
  const searchModal = useRef(null);
  const [isLoading, setisLoading] = useState(false);
  const i18n = useContext<LocalizationContext>(LocalizationContext);
  const { data, error } = useAudioGuidesQuery({
    fetchPolicy: "network-only",
    variables: { page: 1, lang: i18n.getLocale() },
  });
  const { handle: handleError } = useErrorHandler();

  const searchQuery = useSearchQuery({
    fetchPolicy: "network-only",
    variables: {
      input: {
        search: search,
        categoryId: category,
        cityId: city,
        lang: i18n.getLocale(),
      },
    },
  });

  const onSearch = async (categoryId: string, cityId: string) => {
    try {
      setisLoading(true);
      const query = searchQuery.refetch({
        input: {
          search: search,
          categoryId: categoryId,
          cityId: cityId,
          lang: i18n.getLocale(),
        },
      });
      setAudioGuides((await query).data.search as AudioGuide[]);
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  const openSearchModal = () => {
    searchModal.current();
  };

  const setFilterProperties = async (categoryId: string, cityId: string) => {
    setCategory(categoryId);
    setCity(cityId);
    onSearch(categoryId, cityId);
  };

  useEffect(() => {
    setAudioGuides(data?.audioGuides?.data as AudioGuide[]);
  }, [data]);

  useEffect(() => {
    onSearch(category, city);
  }, [search]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  const renderItem = ({ item: guide }: { item: Object }) => (
    <GuideCard guide={guide} />
  );

  return (
    <View flex="1" alignItems="center" width={"100%"}>
      <View maxWidth={WITH_CONTENT} width={"100%"} flex="1">
        <SearchModal
          searchModal={searchModal}
          setFilterProperties={setFilterProperties}
        />
        <View px="4" py="2">
          <Input
            size="md"
            value={search}
            onChangeText={onChangeSearch}
            placeholder={useTranslate("Buscar")}
            borderColor={useThemeColor("border")}
            InputLeftElement={
              <Icon
                as={<MaterialCommunityIcons name="magnify" />}
                size={6}
                ml="2"
                color="muted.400"
                onPress={onSearch}
              />
            }
            InputRightElement={
              <Icon
                as={<MaterialCommunityIcons name="filter-variant" />}
                size={6}
                mr="2"
                color="muted.400"
                onPress={openSearchModal}
              />
            }
          />
          <HStack width="100%" justifyContent={"flex-start"}>
            <Button
              onPress={openSearchModal}
              variant="ghost"
              color="primary.500"
              size="sm"
              leftIcon={
                <Icon
                  as={<MaterialCommunityIcons name="filter" />}
                  size={6}
                  mr="2"
                  color="primary.500"
                />
              }
            >
              {useTranslate("Filtrar")}
            </Button>
          </HStack>
        </View>

        <Heading size="md" px="4" py="2" color={useThemeColor("tint")}>
          {useTranslate("Principales Audioguías")}
        </Heading>
        <FlatList
          refreshing={isLoading}
          onRefresh={() => onSearch(category, city)}
          data={audioGuides}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          p="4"
          ListEmptyComponent={() => (
            <EmptyState
              icon="database-off-outline"
              title={
                useTranslate("No hay ninguna audioguía") + "..."
              }
              subtitle={
                useTranslate(
                  "No se encontraron audioguías con los filtros seleccionados"
                ) + "."
              }
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});

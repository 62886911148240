import { MaterialIcons } from "@expo/vector-icons";
import { Button, Heading, Icon, Image, Input, Text, View } from "native-base";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import { RootStackScreenProps } from "@/types";
import { useRegisterMutation } from "../../types-graphql.d";
import useErrorHandler from "../../hooks/useErrorHandler";
import useTranslate from "../../hooks/useTranslate";
import {
  LanguagesTypes,
  LocalizationContext,
} from "../../i18n/LocalizationProvider";

function RegisterScreen({ navigation }: RootStackScreenProps<"Register">) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, onChangePasword] = React.useState("");
  const [email, onChangeEmail] = React.useState("");
  const [phone, onChangePhone] = React.useState("");
  const [lastName, onChangeLastName] = React.useState("");
  const [name, onChangeName] = React.useState("");
  const [isLoading, setisLoading] = React.useState(false);
  const [registerMutation] = useRegisterMutation();
  const { handle: handleError } = useErrorHandler();

  const i18n = useContext<LocalizationContext>(LocalizationContext);

  // TODO: register mutation
  const onRegister = async () => {
    try {
      setisLoading(true);
      const data = await registerMutation({
        variables: {
          input: {
            name,
            lastName,
            email,
            phone,
            password,
            locale: i18n.getLocale(),
          },
        },
      });
      console.log(data.data?.register);
      navigation.reset({
        index: 0,
        routes: [{ name: "Login" }],
      });
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
      bg="white"
    >
      <View maxWidth={"md"} width={"100%"} flex="1" pt="16">
        <View style={{ alignItems: "center" }}>
          <Heading color={"primary.500"} mb={6}>
            {useTranslate("Crear cuenta")}
          </Heading>
        </View>
        <View>
          <Input
            value={name}
            onChangeText={onChangeName}
            placeholder={useTranslate("Nombre")}
            textContentType="name"
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="person" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
          />
          <Input
            value={lastName}
            onChangeText={onChangeLastName}
            placeholder={useTranslate("Apellidos")}
            textContentType="givenName"
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="person" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
          />
          <Input
            value={email}
            onChangeText={onChangeEmail}
            placeholder={useTranslate("Correo electrónico")}
            textContentType="emailAddress"
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="mail" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
          />
          <Input
            value={phone}
            onChangeText={onChangePhone}
            placeholder={useTranslate("Teléfono")}
            textContentType="telephoneNumber"
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="phone" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
          />
          <Input
            value={password}
            onChangeText={onChangePasword}
            placeholder={useTranslate("Contraseña")}
            secureTextEntry={!showPassword}
            textContentType={showPassword ? "none" : "password"}
            type={showPassword ? "text" : "password"}
            mb="2"
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="lock" />}
                size={5}
                ml="2"
                color="muted.200"
              />
            }
            InputRightElement={
              <Icon
                as={
                  <MaterialIcons
                    name={showPassword ? "visibility" : "visibility-off"}
                  />
                }
                size={5}
                mr="2"
                color="muted.200"
                onPress={() => setShowPassword(!showPassword)}
              />
            }
          />
          <Button mb="2" onPress={onRegister.bind(this)} isLoading={isLoading}>
            {useTranslate("Registrarse")}
          </Button>
          <Button
            variant="outline"
            onPress={() =>
              navigation.reset({
                index: 0,
                routes: [{ name: "Root" }],
              })
            }
          >
            {useTranslate("Por ahora, seguir como invitado")}
          </Button>
        </View>
      </View>
    </View>
  );
}

export default RegisterScreen;

import Colors from '../constants/Colors';
import { ColorSchemeName, useColorScheme, useColorScheme as _useColorScheme } from 'react-native';

export default function useThemeColor(
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark,
): string {
    _useColorScheme() as NonNullable<ColorSchemeName>
  
  //force light theme
  //TODO: s'han de repasar totes les pantalles en modo dark
  const theme = 'light'; //_useColorScheme();
  
  return Colors[theme ? theme : 'light'][colorName];
}
import { gql, useMutation } from "@apollo/client";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import {
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Text,
  View,
} from "native-base";
import React, { useEffect } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";
import useErrorHandler from "../../hooks/useErrorHandler";
import useTranslate from "../../hooks/useTranslate";
import { saveUser, updatePreferredLangUser } from "../../redux/actions";

import { RootStackScreenProps } from "../../types";
import { MutationLoginArgs, useLoginMutation } from "../../types-graphql.d";

function LoginScreen({ navigation }: RootStackScreenProps<"Login">) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, onChangePasword] = React.useState("");
  const [email, onChangeEmail] = React.useState("");
  const [isLoading, setisLoading] = React.useState(false);
  const [loginMutation] = useLoginMutation();
  const dispatch = useDispatch();
  const { handle: handleError } = useErrorHandler();
  const WEB = "web"

  const onLogin = async () => {
    try {
      setisLoading(true);
      const data = await loginMutation({
        variables: { email: email, password: password, source: WEB },
      });
      console.log(data.data?.login);
      dispatch(await saveUser({ token: data.data?.login?.token }));
      dispatch(await updatePreferredLangUser(data.data?.login?.preferredLang));
      onChangeEmail("");
      onChangePasword("");
      navigation.reset({
        index: 0,
        routes: [{ name: "Root" }],
      });
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Image
        h={"268px"}
        w={"100%"}
        resizeMode="cover"
        source={require("../../assets/images/login-hero.png")}
      />
      <View
        flex={1}
        bg={"white"}
        _dark={{ bg: "black" }}
        style={{
          borderTopRightRadius: 40,
          borderTopLeftRadius: 40,
          marginTop: -40,
        }}
        justifyContent="center"
        alignItems={"center"}
      >
        <View maxWidth={"md"} width={"100%"} flex="1" pt="16">
          <View style={{ alignItems: "center" }}>
            <Image
              h={"16"}
              w={"100%"}
              resizeMode="contain"
              mb="6"
              source={require("../../assets/images/logo-light.png")}
            />
          </View>
          <View>
            <Input
              value={email}
              onChangeText={onChangeEmail}
              placeholder={useTranslate("Correo electrónico")}
              textContentType="emailAddress"
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="mail" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
            />
            <Input
              value={password}
              onChangeText={onChangePasword}
              placeholder={useTranslate("Contraseña")}
              secureTextEntry={!showPassword}
              textContentType={showPassword ? "none" : "password"}
              type={showPassword ? "text" : "password"}
              mb="2"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="lock" />}
                  size={5}
                  ml="2"
                  color="muted.200"
                />
              }
              InputRightElement={
                <Icon
                  as={
                    <MaterialIcons
                      name={showPassword ? "visibility" : "visibility-off"}
                    />
                  }
                  size={5}
                  mr="2"
                  color="muted.200"
                  onPress={() => setShowPassword(!showPassword)}
                />
              }
            />
            <HStack>
              <Text
                mb="2"
                style={{ textAlign: "right" }}
                onPress={() => navigation.navigate("ResetPassword")}
              >
                {useTranslate("Has olvidado la contraseña?")}
              </Text>
            </HStack>
            <Button mb="2" onPress={onLogin.bind(this)} isLoading={isLoading}>
              {useTranslate("Iniciar sesión")}
            </Button>

            <Button
              variant="outline"
              onPress={() =>
                navigation.reset({
                  index: 0,
                  routes: [{ name: "Root" }],
                })
              }
            >
              {useTranslate("Por ahora, seguir como invitado")}
            </Button>

            <Button
              mb="2"
              colorScheme="black"
              variant="link"
              _text={{ fontWeight: "bold" }}
              onPress={() => navigation.navigate("Register")}
            >
              <HStack alignItems={"center"}>
                <Text>{useTranslate("No tienes cuenta?")}</Text>
                <Text fontWeight={"bold"}> {useTranslate("Crear")}</Text>
              </HStack>
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
}

export default LoginScreen;

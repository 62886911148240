import { useNavigation } from "@react-navigation/native";
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from "native-base";
import React, { useRef } from "react";
import useGetDuration from "../hooks/useGetDuration";
import useTranslate from "../hooks/useTranslate";
import DownloadAppModal from "./DownloadAppModal";

function MyGuideCard({ guide }) {
  const navigation = useNavigation();
  
  const downloadModal = useRef(null);

  function goToDetail() {
    downloadModal?.current();
  }

  return (
    <Center>
      <DownloadAppModal
          downloadAppModal={downloadModal}
        />
      <Box
        rounded="lg"
        overflow="hidden"
        borderColor="coolGray.200"
        borderWidth="1"
        maxWidth={"750px"}
        width={"100%"}
        mb={"2"}
        _light={{
          backgroundColor: "gray.50",
        }}
      >
        <Box>
          <Image
            source={{
              uri: guide.imageUrl,
            }}
            w="100%"
            h="48"
            alt="image"
          />
        </Box>
        <Stack p="4" space={3}>
          <Heading size="md">{guide.title}</Heading>
          <HStack alignItems="center" space={4} justifyContent="space-between">
            <HStack alignItems="center">
              <Text
                color="coolGray.600"
                _dark={{
                  color: "warmGray.200",
                }}
                fontWeight="400"
              >
                {useGetDuration(guide.duration)}
              </Text>
            </HStack>
          </HStack>
          <Stack>
            <Center>
              <Button w="sm" bg="primary.500" onPress={goToDetail}>
                {useTranslate("Visitar")}
              </Button>
            </Center>
          </Stack>
        </Stack>
      </Box>
    </Center>
  );
}

export default MyGuideCard;

import { Button, Text, View } from "native-base";
import { StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useCheckConnectionQuery } from "../types-graphql.d";
import { useNavigation } from "@react-navigation/native";
import useTranslate from "../hooks/useTranslate";

export default function MaintenanceScreen({ route }: { route: any }) {
  const { loading, error, refetch } = useCheckConnectionQuery({
    fetchPolicy: "network-only",
  });

  const navigation = useNavigation();

  async function checkConnection() {
    const { data } = await refetch();
    if (data.checkConnection) {
      navigation.reset({
        index: 0,
        routes: [{ name: "Root" }],
      });
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <Ionicons name="construct-sharp" size={48} color={"primary.500"} />
      </View>
      <Text style={styles.title}>
        {useTranslate("La aplicación está en modo mantenimiento")}.
      </Text>
      <Text style={styles.text}>
        {useTranslate(
          "Esta aplicación se encuentra actualmente en mantenimiento programado"
        )}
        .{useTranslate("En breve estaremos de vuelta")}.
      </Text>
      <Button
        onPress={() => checkConnection()}
        style={{ marginTop: 12 }}
        loading={loading}
      >
        {useTranslate("Volver a intentarlo")}
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "white.500",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: "primary.500",
    textAlign: "center",
  },
  text: {
    color: "primary.500",
    textAlign: "center",
  },
  iconContainer: {
    padding: 10,
    borderRadius: 96,
    backgroundColor: "primary.500",
    marginBottom: 16,
  },
});
